import { useParams } from "react-router-dom";
import StoreitemBookController from "../controllers/StoreitemBookController";

import StoreItemDetailController from "../controllers/StoreItemDetailController";
import "./../css/StoreItemDetail.css";
import "./../css/Detail.css";



const StoreItemDetail = ({ hideSearchForm  }) => {

  const params = useParams();
  console.log(params.store,'para');

  if(params.store!="lawyers")
  {

    return <StoreitemBookController  hideSearchForm ={hideSearchForm}  {...params}></StoreitemBookController>;

  }
  else{
    return <StoreItemDetailController  hideSearchForm ={hideSearchForm}  {...params}></StoreItemDetailController>;
  }
  
};

export default StoreItemDetail;
