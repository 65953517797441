import { Component } from "react"; 
import { Link } from "react-router-dom";
import { titleSlug } from "../../config/common"; 
import Lawyer from ".";
import Catalog from "..";
import { Button } from "react-bootstrap";

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isloaded:false,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  componentDidMount() {
    this.intervalId=setInterval(this.refreshlist, 1000); 
  }

  componentDidUpdate() {}

  
  componentWillUnmount() { 
    clearInterval(this.intervalId);
  }
  refreshlist=async ()=>{ 
    const isloaded=Catalog.isLoad;
    this.setState({isloaded})
  }

  render() {
    return (
      <div className="store-item">
            <div className="store-item-body">
              <div className="store-avathar">
                <img src={this.state.storeItem.image} alt={this.state.storeItem.Title} />
              </div>
              <div className="store-detail">
                <div className="store-title">
                  <span>{this.state.storeItem.Title}</span>
                </div>
                <div className="store-sub-title">
                  <span>{this.state.storeItem.SubTitle}</span>
                </div> 
                <div className="store-sub">
                  <span>Practice Since</span>
                  {/* <span>{Catalog.Lawyer.Profile.DisplayDate}</span> */}
                  <span>: {this.state.storeItem.DisplayDate}</span>
                </div> 
                {this.state.isloaded&&this.state.storeItem.PropertyList1 && Catalog.Lawyer.Config.PropertyList1 && Array.isArray(Catalog.Lawyer.Config.PropertyList1) && Catalog.Lawyer.Config.PropertyList1.filter((i) => this.state.storeItem.PropertyList1.includes(Number.parseInt(i.value))).length > 0 && (
                        <div className="store-sub">
                          <span>Practice Area</span>
                          {/* <span>{Catalog.Lawyer.Profile.PropertyList1}</span> */}
                          <span>:

                            {
                              Catalog.Lawyer.Config.PropertyList1.filter((i) => this.state.storeItem.PropertyList1.includes(Number.parseInt(i.value))).map((i) => (<span key={i.value}>  {i.label}</span>))
                            }
                          </span>
                        </div>
                      )}
                       <div className="store-actions">
                       <Link className="profile-btn btn btn-primary" to={`/catalogs/lawyers/${this.state.storeItem.id}/${titleSlug(this.state.storeItem.Title)}`}>
                       View Profile 
                        </Link>
                        <a className="btn btn-primary" href={"tel:"+this.state.storeItem.contact?.phone_number}>Call for Free Consultation</a>
                       </div>
              </div> 
            </div>
      </div>
    );
  }
}
export default Card;
