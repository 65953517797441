import React, { Component } from "react";
import FindLawerData from './FindLawerData'


class FindLawerContoller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props
    };
  }
  componentDidMount() {
    document.body.classList.add('lawyerclass'); 
}

componentWillUnmount() {
    document.body.classList.remove('lawyerclass'); 
}




render(){

  return (
    <div>
        <h2>See lawyers by practice area</h2>
        <p>Choose an area of law to find top-rated attorneys near you.</p>
        <FindLawerData ></FindLawerData>
        <div className='lawer-sub-sec'>
        <h4>How to find and hire a great lawyer</h4>
        <h5>Do I need a lawyer?</h5>
        <div className="law-text">
        <h6>What do lawyers do, exactly?</h6>
        <p>Lawyers (also called attorneys or counsel) serve as advocates for people and organizations. They represent clients both to the court and to opposing parties. Lawyers can represent clients in criminal cases, where a law has been broken, and in civil cases, in which one party is suing another.
         Lawyers are also important partners in situations that don’t involve the courtroom, advising clients about their legal rights and obligations for personal or business issues. They are trained to interpret complicated systems of laws and navigate the court system.</p>
        </div>
        <div className="law-text">
        <h6>What do lawyers do, exactly?</h6>
        <p>Lawyers (also called attorneys or counsel) serve as advocates for people and organizations. They represent clients both to the court and to opposing parties. Lawyers can represent clients in criminal cases, where a law has been broken, and in civil cases, in which one party is suing another.
         Lawyers are also important partners in situations that don’t involve the courtroom, advising clients about their legal rights and obligations for personal or business issues. They are trained to interpret complicated systems of laws and navigate the court system.</p>
        </div>
        </div>

        


    </div>
  )
}
}



export default FindLawerContoller
