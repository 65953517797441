
import React from 'react';
const FindLawerData = () => {


  const LawerItems = [
    { name: "Bankruptcy and debt", link: "/" },
    { name: "Child custody", link: "/" },
    { name: "Divorce and separation", link: "/" },
    { name: "Foreclosure", link: "/" },
    { name: "Mesothelioma and asbestos", link: "/" },
    { name: "Real estate", link: "/" },
    { name: "Trucking accident", link: "/" },


    { name: "Bankruptcy and debt", link: "/" },
    { name: "Child custody", link: "/" },
    { name: "Divorce and separation", link: "/" },
    { name: "Foreclosure", link: "/" },
    { name: "Mesothelioma and asbestos", link: "/" },
    { name: "Real estate", link: "/" },
    { name: "Trucking accident", link: "/" },


    { name: "Bankruptcy and debt", link: "/" },
    { name: "Child custody", link: "/" },
    { name: "Divorce and separation", link: "/" },
    { name: "Foreclosure", link: "/" },
    { name: "Mesothelioma and asbestos", link: "/" },
    { name: "Real estate", link: "/" },
    { name: "Trucking accident", link: "/" },


    { name: "Bankruptcy and debt", link: "/" },
    { name: "Child custody", link: "/" },
    { name: "Divorce and separation", link: "/" },
    { name: "Foreclosure", link: "/" },
    { name: "Mesothelioma and asbestos", link: "/" },
    { name: "Real estate", link: "/" },
    { name: "Trucking accident", link: "/" },
   
  ];


  const LawerItems2 = [
    { name: "Bankruptcy and debt", link: "/" },
    { name: "Child custody", link: "/" },
    { name: "Divorce and separation", link: "/" },
    { name: "Foreclosure", link: "/" },
    { name: "Mesothelioma and asbestos", link: "/" },
    { name: "Real estate", link: "/" },
    { name: "Trucking accident", link: "/" },


    { name: "Bankruptcy and debt", link: "/" },
    { name: "Child custody", link: "/" },
    { name: "Divorce and separation", link: "/" },
    { name: "Foreclosure", link: "/" },
    { name: "Mesothelioma and asbestos", link: "/" },
    { name: "Real estate", link: "/" },
    { name: "Trucking accident", link: "/" },


    { name: "Bankruptcy and debt", link: "/" },
    { name: "Child custody", link: "/" },
    { name: "Divorce and separation", link: "/" },
    { name: "Foreclosure", link: "/" },
    { name: "Mesothelioma and asbestos", link: "/" },
    { name: "Real estate", link: "/" },
    { name: "Trucking accident", link: "/" },


    { name: "Bankruptcy and debt", link: "/" },
    { name: "Child custody", link: "/" },
    { name: "Divorce and separation", link: "/" },
    { name: "Foreclosure", link: "/" },
    { name: "Mesothelioma and asbestos", link: "/" },
    { name: "Real estate", link: "/" },
    { name: "Trucking accident", link: "/" },
   
  ];


  const LawerItems3 = [
    { name: "Bankruptcy and debt", link: "/" },
    { name: "Child custody", link: "/" },
    { name: "Divorce and separation", link: "/" },
    { name: "Foreclosure", link: "/" },
    { name: "Mesothelioma and asbestos", link: "/" },
    { name: "Real estate", link: "/" },
    { name: "Trucking accident", link: "/" },


    { name: "Bankruptcy and debt", link: "/" },
    { name: "Child custody", link: "/" },
    { name: "Divorce and separation", link: "/" },
    { name: "Foreclosure", link: "/" },
    { name: "Mesothelioma and asbestos", link: "/" },
    { name: "Real estate", link: "/" },
    { name: "Trucking accident", link: "/" },


    { name: "Bankruptcy and debt", link: "/" },
    { name: "Child custody", link: "/" },
    { name: "Divorce and separation", link: "/" },
    { name: "Foreclosure", link: "/" },
    { name: "Mesothelioma and asbestos", link: "/" },
    { name: "Real estate", link: "/" },
    { name: "Trucking accident", link: "/" },


    { name: "Bankruptcy and debt", link: "/" },
    { name: "Child custody", link: "/" },
    { name: "Divorce and separation", link: "/" },
    { name: "Foreclosure", link: "/" },
    { name: "Mesothelioma and asbestos", link: "/" },
    { name: "Real estate", link: "/" },
    { name: "Trucking accident", link: "/" },
   
  ];

  return (
    <div className='lawer-ul-sec'>
        <div className='lawer-1-sec l-te'>
        <ul className="lawer-list">
        {LawerItems.map((item, index) => (
          <li key={index} className="lawer-item">
            <a href={item.link}>{item.name}</a>
          </li>
        ))}
       </ul>
        </div>

        <a class="practice-area" href="">See all pratice area</a>
             {/* ------2nd------- */}


        <div className='lawer-2-sec l-te'>
        <h2>View attorneys by state</h2>

        <ul className="lawer-list">
        {LawerItems2.map((item, index) => (
          <li key={index} className="lawer-item">
            <a href={item.link}>{item.name}</a>
          </li>
        ))}
      </ul>
        </div>

             {/* ------3rd------- */}


        <div className='lawer-3-sec l-te'>
        <h2>View attorneys by city</h2>

        <ul className="lawer-list">
        {LawerItems3.map((item, index) => (
          <li key={index} className="lawer-item">
            <a href={item.link}>{item.name}</a>
          </li>
        ))}
      </ul>
        </div>
         


       




      
    </div> 

 









  );
};

export default FindLawerData;
