import React, { Component } from "react";
import { AppContext } from "../config/common";
import Catalog from "../Catalog";
import { Navigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { StoreService } from "../services/store"; 
import FormCheckGroup from "../widgets/FormCheckGroup";
import PriceFilter from "../widgets/PriceFilter";
import BindingCheck from "../widgets/BindingCheck";
import BookSearch from "./../widgets/BookSearch";

import { BookService } from "../services/book";
import FormDropdown from "../widgets/FormDropdown";
import FormSearchDropdown from "../widgets/FormSearchDropdown";
class StoreController extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    console.log(Catalog.Flash)
    this.state = {
      ...props,
      storeItems: {
        data: [],
        pagination: {
          page: 1,
          pageSize: 25,
          pageCount: 1,
          total: 0,
        },
      },
      filterData: { 
        PropertyList1:Catalog.Flash.PropertyList1||[],
        PropertyList2:[],
        PropertyList3:[],
        Property1:Catalog.Flash.Property1||[],
        Property2:Catalog.Flash.Property2||[],
        Category:Catalog.Flash.Category||[],
        author_names:[],
        publishers:[],
        SubTitle:[],
        Price:[],
        Binding:[],
        Type:[],
        Search:[],
        
      },
      availableFilterData: { 
        PropertyList1:[],
        PropertyList2:[],
        PropertyList3:[],
        Category:[],
        author_names:[],
        publishers:[],  
        SubTitle:[],
        Price:[],
        Binding:[],
        Type:[],
        Search:[],
       
      },
      authors:[],
      storeName: "",
      isloaded:false,
      isShowFilter:false,
      priceRanges: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  componentDidMount() {

    this.context.updateHideSearchForm(true);

    window.scrollTo(0, 0); 
    this.intervalId=setInterval(this.refreshlist, 1000); 
    if(this.state.store==="books"){
      this.fetchAuthors();
    }
    this.fetchStore(this.state.filterData);
    this.fetchFilterIds();
  }
  componentDidUpdate() {
    if (this.state.store !== this.state.storeName) {
      this.fetchStore();
    }
    // this.state.storeItems.data.map((storeItem, index) => {
    //   console.log("Updated storeItems:", storeItem.Price);
    // })
  }
 
  handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("Form submitted:");
  };

  componentWillUnmount() { 
    this.context.updateHideSearchForm(false); 
    clearInterval(this.intervalId);
  }
  refreshlist=async ()=>{ 
    if(!this.state.isloaded&&Catalog.isLoad){
      await Catalog.refreshConfig();
    }
    const isloaded=Catalog.isLoad;
    this.setState({isloaded})
  }

  fetchAuthors=async ()=>{
    const authors=await BookService.fetchAuthors();
    this.setState({ authors }); 
  }

  fetchFilterIds=async ()=>{
    const storeName = this.state.store;
    const availableFilterData=await StoreService.fetchFilterIds(storeName);
    console.log(availableFilterData)
    this.setState({ availableFilterData });  
  }

  fetchStore=async (filterData={})=> {
    const storeName = this.state.store;
    this.setState({ storeName });  
    const storeItems = await StoreService.fetchStore(storeName,filterData); 
    this.setState({ storeItems });
  }
  handleChange=(e)=>{
    const {name,value,checked} = e.target;
    let filterData = this.state.filterData;
 
    if(checked){
      filterData[name]=[...filterData[name],value];
    }else{
      filterData[name]=filterData[name].filter((v,k)=>v!==value)
    }
    const storeItems ={
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    };
    console.log(filterData);
    this.setState({ filterData ,storeItems })
    this.fetchStore(filterData);
  }

  handleChangeDropDown=(e)=>{
    const {name,value} = e.target;
    const filterData = {
      ...this.state.filterData,
      [name]:value
    };
    const storeItems ={
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    }; 
    this.setState({ filterData ,storeItems })
    this.fetchStore(filterData);
  }
  // handlePriceFilterChange=(e)=>{
  //   const {name,value,checked} = e.target;
  //   let filterData = this.state.filterData;
  //   filterData.Price = []; 

  //   if(checked){
  //     filterData[name]=[...filterData[name],value];
  //   }else{
  //     filterData[name]=filterData[name].filter((v,k)=>v!==value)
  //   }

  //   const storeItems ={
  //     data: [],
  //     pagination: {
  //       page: 1,
  //       pageSize: 25,
  //       pageCount: 1,
  //       total: 0,
  //     },
  //   };
  //   console.log(filterData);
  //   this.setState({ filterData ,storeItems })
  //   this.fetchStore(filterData);

  // }

  handlePriceFilterChange = (selectedPriceRanges) => {
    const { filterData } = this.state;

    filterData.Price = selectedPriceRanges; 
    
    const storeItems = {
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    };

    this.setState({ filterData, storeItems }, () => {
   
      this.fetchStore(filterData); 
    });
  };
  
  handleBindingFilterChange = (newSelectedValues) => {
    const { filterData } = this.state;

    filterData.Binding = newSelectedValues; 
    
    const storeItems = {
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    };

    this.setState({ filterData, storeItems }, () => {
   
      this.fetchStore(filterData); 
    });
  };

  handleTypeFilterChange = (newSelectedValues) => {
    const { filterData } = this.state;

    filterData.Type = newSelectedValues; 
    
    const storeItems = {
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    };

    this.setState({ filterData, storeItems }, () => {
   
      this.fetchStore(filterData); 
    });
  };

  getAvailablePriceRanges(availableFilterData) {
   
    const prices = availableFilterData.Price.map(item => item); 
  
   
    if (prices.length === 0) return [];
  
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
  
    const ranges = [];
  
    const filteredRanges = [];
  
    if (maxPrice > 0) {
      let currentMax = 0; 
      while (currentMax <= maxPrice) {
        const nextMax = currentMax + 500;
        const label = `${currentMax + 1} - ${nextMax}`;
        const value = `${currentMax + 1}-${nextMax}`;
        
       
        const rangePrices = prices.filter(price => price >= currentMax + 1 && price <= nextMax);
        if (rangePrices.length > 0) {
          filteredRanges.push({ label, value });
        }
        
        currentMax = nextMax; 
      }
    }
  
    return filteredRanges;
  }

  

  getMaxPrice(availableFilterData) {
 
    const prices = availableFilterData.Price.map(item => item);

    const maxPrice = Math.max(...prices);

    const roundToNearest = (value) => {
        const magnitude = Math.pow(10, Math.floor(Math.log10(value)));  

        return Math.ceil(value / magnitude) * magnitude;  
    };
      return roundToNearest(maxPrice);
  }

  
  handleSearchResult = (searchQuery) => {

    console.log("Search query received:", searchQuery);
    const { filterData } = this.state;

    filterData.Search = searchQuery; 
    
    const storeItems = {
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    };

    this.setState({ filterData, storeItems }, () => {
   
      this.fetchStore(filterData); 
    });
    
  };
  
  
  

  // handlePriceFilterChange = (filterName, selectedRanges) => {
  //   const filterData = { ...this.state.filterData, [filterName]: selectedRanges };
  //   this.setState({ filterData }, this.fetchStore);
  // };

  
  render() {

    const { availableFilterData } = this.state; 

    const maxPrice = this.getMaxPrice(availableFilterData);

    let catalog = Catalog.Book; 

    let userlog = Catalog.Session;
    let searchtext = "Search from millions of books...";
    
    switch (this.state.store) {
      case "books":
        catalog = Catalog.Book;
        searchtext = "Search by title, author, category, publisher or tag";
        break;
      case "lawyers":
        catalog = Catalog.Lawyer;
        searchtext = "Search by Lawyer name, Specialization Area";
        break;
      case "firms":
        catalog = Catalog.Firm;
        searchtext = "Search by Firm name, Specialization Area";
        break;

      default:
        return (
          <>
            <Navigate replace to="/404" />
          </>
        );
    }  
    return (
      <>
        <div className="store-wrap">
          <div className="row">
            <div className="col-md-12 serch-col">
              
            <BookSearch
                formClass="top-serch m-2"
                placeholder={searchtext}
                bookresult={this.handleSearchResult}                
              />
            </div>
            <div className="col-md-3"  >
              <Card className={this.state.isShowFilter?"mobile-fade active":"mobile-fade"}> 
              {/* {this.state.store!=="books"&&<BookSearch
                formClass="top-serch"
                placeholder={"Search from millions of books..."}
                bookresult={this.handleSearchResult}  
                
              />} */}

              
                <Card.Header>
                  <span>Filters</span>
                  <button className="mobile-fade-btn" onClick={()=>{
                    const isShowFilter=!this.state.isShowFilter;
                    this.setState({isShowFilter});
                  }}></button>
                </Card.Header>
                <Card.Body>
                  <div className="data">
                    {this.state.store==="books"? ( <>
                      {catalog.Config.Category&&this.state.availableFilterData.Category.length>0&&(<div className="col-md-12"> 
                        <FormSearchDropdown
                          id={"filter-book"}
                          title={catalog.Profile.Category}
                          value={this.state.filterData?.Category}
                          options={catalog.Config.Category.filter((itm)=>(this.state.availableFilterData.Category||[]).includes(itm.value))}
                          name={"Category"}
                          handle={this.handleChange}
                          placeholder={"Select Category"}
                        />
                      </div>)}

                      {/* {this.state.store!=="books"&&this.state.availableFilterData.Binding.length>0&&(<div className="col-md-12"> 
                        <BindingCheck
                          id={"filter-binding"}
                          title='Binding'
                          value=""
                          options={this.state.availableFilterData.Binding}
                          name="Binding"
                          onBindChange={this.handleBindingFilterChange}
                        />
                      </div>)} */}

                      {this.state.availableFilterData.Type.length>0&&(<div className="col-md-12"> 
                        <FormSearchDropdown
                          id={"filter-type"}
                          title='Type'
                          value={this.state.filterData?.Type}
                          options={[...this.state.availableFilterData.Type.map((i)=>{ return {label:i,value:i}})]}
                          name="Type"
                          handle={this.handleChange}
                          placeholder={"Select Type"}
                        />
                      </div>)}

                      
                      {catalog.Config.author_names&&this.state.availableFilterData.author_names.length>0&&(<div className="col-md-12"> 
                        <FormSearchDropdown
                          id={"filter-author"}
                          title="Author"
                          value={this.state.filterData?.author_names}
                          options={catalog.Config.author_names.filter((itm)=>(this.state.availableFilterData.author_names||[]).includes(itm.value))}
                          name={"author_names"}
                          handle={this.handleChange}
                          placeholder={"Select Author"}
                        />
                      </div>)}


                      

                      {catalog.Config.publishers&&this.state.availableFilterData.publishers.length>0&&(<div className="col-md-12"> 
                        <FormSearchDropdown
                          id={"filter-publisher"}
                          title="Publisher"
                          value={this.state.filterData?.publishers}
                          options={catalog.Config.publishers.filter((itm)=>(this.state.availableFilterData.publishers||[]).includes(itm.value))}
                          name={"publishers"}
                          handle={this.handleChange}
                          placeholder={"Select Publisher"}
                        />
                      </div>)}

                      
                      {this.state.storeItems&&(<div className="col-md-12"> 
                        <PriceFilter
                            storeItems={this.state.storeItems}
                            onFilterChange={this.handlePriceFilterChange}
                            name={"Price"}
                            priceRanges={maxPrice}
                          />
                      </div>)}

                    {catalog.Config.SubTitle&&this.state.availableFilterData.SubTitle.length>0&&(<div className="col-md-12"> 
                      <FormCheckGroup
                          id={"filter-author"}
                        title={catalog.Profile.SubTitle}
                        value={this.state.filterData?.SubTitle}
                        options={this.state.authors.filter((itm)=>(this.state.availableFilterData.SubTitle||[]).includes(itm.value))}
                        name={"SubTitle"}
                        handle={this.handleChange}
                      />
                    </div>)}
                      </>
                    ) : (
                      <>

                        {catalog.Config.PropertyList3&&this.state.availableFilterData.PropertyList3.length>0&&(<div className="col-md-12"> 
                        <FormCheckGroup
                          id={"filter-l3"}
                          title={catalog.Profile.PropertyList3}
                          value={this.state.filterData?.PropertyList3}
                          options={catalog.Config.PropertyList3.filter((itm)=>(this.state.availableFilterData.PropertyList3||[]).includes(itm.value))}
                          name={"PropertyList3"}
                          handle={this.handleChange}
                        />
                      </div>)}
                      {catalog.Config.PropertyList2&&this.state.availableFilterData.PropertyList2.length>0&&(<div className="col-md-12"> 
                        <FormCheckGroup
                          id={"filter-l2"}
                          title={catalog.Profile.PropertyList2}
                          value={this.state.filterData?.PropertyList2}
                          options={catalog.Config.PropertyList2.filter((itm)=>(this.state.availableFilterData.PropertyList2||[]).includes(itm.value))}
                          name={"PropertyList2"}
                          handle={this.handleChange}
                        />
                      </div>)}
                      
                      </>
                    )}


                    {this.state.store!=="books"&&catalog.Config.PropertyList1&&this.state.availableFilterData.PropertyList1.length>0&&(<div className="col-md-12"> 
                      <FormCheckGroup
                          id={"filter-l1"}
                        title={catalog.Profile.PropertyList1}
                        value={this.state.filterData?.PropertyList1}
                        options={catalog.Config.PropertyList1.filter((itm)=>(this.state.availableFilterData.PropertyList1||[]).includes(itm.value))}
                        name={"PropertyList1"}
                        handle={this.handleChange}
                      />
                    </div>)}

                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-9">
              <div className="row">
                {/* <Card>
                
                <Card.Header>
                  <span>
                    Showing Products({this.state.storeItems.pagination.total})
                  </span>
                </Card.Header>
                <Card.Body>
                
                </Card.Body>
              </Card> */}
              
              {/* <div>
              <span>
                    Showing Products({this.state.storeItems.pagination.total})
                  </span>
              </div> */}

              <div className="store-items  gy-4 row">
                {this.state.storeItems.data.length>0? this.state.storeItems.data.map((storeItem, index) => {
                      return (

                        <>
                        <catalog.Card
                          key={index}
                          storeItem={storeItem}
                          catalog={catalog}
                        ></catalog.Card>
                        </>
                      );
                    }):(<span>No result found</span>)}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default StoreController;
