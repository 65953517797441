import React, { Component } from "react";
import RecentNews from "../widgets/RecentNews";
import SideBarAdCard from "../widgets/SideBarAdCard";
import { Helmet } from "react-helmet";




class SuccessController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList.add('hidesearchclass', 'hidesuccess'); 
}

componentWillUnmount() {
    document.body.classList.remove('hidesearchclass', 'hidesuccess'); 
}

  render() {

    const title = "Success";
    const description = "Success";
    const imageUrl = "https://techlegalonline.com/static/media/logo.978760d800d47dff4309.png"; // Replace with your image URL
    const url = "https://techlegalonline.com/success";

    return (<>

      <Helmet>

        {/* Standard meta tags */}
        <title>{title}</title>
        <meta name="description" content={description} />

        {/* Open Graph meta tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />

      </Helmet>

      <div className="home-page-outer">
        <div className="row row-two">
          <div className="col-md-9">
            <div className="about-wrap">
              <div className="about-row row">
                <div className="about-col col-md-12">
                  <h2>Thank you for Your Purchase!</h2>
                  <p>Your Payment has been successfully processed. <br></br> Please check your registered email for more details.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>);
  }

}

export default SuccessController;