import React from 'react'

const AllLawerData = () => {
  return (
    <div>
      <div className='lawer-sub-sec all-lawer-sec'>
        <h2>How to find and hire a great lawyer</h2>
        <div className="law-text">
        <p>Lawyers (also called attorneys or counsel) serve as advocates for people and organizations. They represent clients both to the court and to opposing parties. Lawyers can represent clients in criminal cases, where a law has been broken, and in civil cases</p>
        <p>Lawyers (also called attorneys or counsel) serve as advocates for people and organizations. They represent clients both to the court and to opposing parties. Lawyers can represent clients in criminal cases, where a law has been broken, and in civil cases</p>
        </div>

        <div className='pratice-area-lawer'>
            <div>
                <p><a>Pratice Areas</a></p>
            </div>
            <div>
            <p><a>Pratice Areas</a></p>
            </div>
            <div>
            <p><a>Pratice Areas</a></p>
            </div>
            <div>
            <p><a>Pratice Areas</a></p>
            </div>
        </div>
        
       
        </div>
      
    </div>
  )
}

export default AllLawerData
