import { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { titleSlug } from "../../config/common";
import { CartService } from "../../services/cart";
import Catalog from "../index";
import { Spinner } from "react-bootstrap";


class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isloaded: false,
      isloading:false,
    }; 
 

  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }

  refreshlist = async () => {

    if (!this.state.isloaded && Catalog.isLoad) {
      await Catalog.refreshConfig();
    }
    const isloaded = Catalog.isLoad; 
    this.setState({ isloaded })
  }


  // addedtoCart = async (id) => {

  //   const bookId = id;

  //   const userId = Catalog.Session.user.id;

  //   this.setState({ loading: true });
  //   try {
  //     const cart = await CartService.CreateCart(userId, bookId);

  //     if (cart) {

  //       this.setState({ successMessage: "Book Added to Cart" });

  //       this.props.navigate(`/cart`);

  //     } else {

  //       this.setState({ errorMessage: "Error: Please try again later." });

  //     }
  //   } catch (error) {

  //     console.error("Error adding to cart:", error);

  //     this.setState({ errorMessage: "Error: Unable to add book to cart." });

  //   } finally {

  //     this.setState({ loading: false });
  //   }

  // };

  handleAddToCart = async (e) => {

    console.log(this.state.storeItem,'eeeeeeerrrrrr');

    e.preventDefault(); 
    if(!this.state.isloading){
      this.setState({isloading:true})
      await Catalog.addToCart(this.state.storeItem)
    }
    this.setState({isloading:false}) 
  };

  componentDidMount() {

    this.intervalId = setInterval(this.refreshlist, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  componentDidUpdate() { }

  render() {

    let cartitems = (Catalog.Cart.items||[]).filter((item)=> item.id===this.state.storeItem.id)||[];

    console.log(this.state.storeItem); 

    return (
      <>
      <div className="product-card">
      <img src={this.state.storeItem.image} alt="Book Cover" />
      <div className="product-info">

        <h2 className="product-title">{this.state.storeItem.Title}</h2>
        {this.state.storeItem.author_names.data && this.state.storeItem.author_names.data.length > 0 && this.state.catalog.Config.author_names && this.state.catalog.Config.author_names.length > 0 && (
        <p className="product-author">
           <div className="store-title author">
                By &nbsp;
                {Array.isArray(this.state.catalog.Config.author_names) && this.state.catalog.Config.author_names.length > 0 &&
                  this.state.catalog.Config.author_names.map((i, index) => {

                    const authorData = this.state.storeItem.author_names.data;

                    const authorLenght = this.state.storeItem.author_names.data.length;

                    if (authorData.some(item => item.id && item.id === Number.parseInt(i.value)) && i.label && i.label !== "") {
                      return (
                        <span key={i.value}>
                          {index > 0 && authorLenght != 1 && ", "}
                          {i.label}
                        </span>
                      );
                    }
                    return null;
                  })
                }
              </div>
        </p>
       )}

        <p className="product-description">{this.state.storeItem.ShortDescription}</p>
        <div className="product-price">
          {this.state.storeItem.discount&&this.state.storeItem.discount>0&&this.state.storeItem.Price&&this.state.storeItem.Price>0?(<>      
            <span className="product-price-current"> ₹{(this.state.storeItem.Price*(100-this.state.storeItem.discount))/100} </span>    
            <span className="product-price-original">MRP: ₹{this.state.storeItem.Price } </span>
          <span className="product-price-offer">&nbsp;&nbsp; ({this.state.storeItem.discount}% off)</span>
          </>):(<>
            <span className="product-price-current">₹{this.state.storeItem.Price}</span>
          
          </>)}
        </div>
       
        <div className="product-actions">

              {this.state.isloaded&&(<Link  className="product-button btn1" onClick={this.handleAddToCart}  >   Add To Cart  {cartitems.length>0&&(<>({cartitems[0].count})</>)} {this.state.isloading&&(<Spinner size="sm" />)} </Link>)}

              <Link to={`/catalogs/books/${this.state.storeItem.id}/${titleSlug(this.state.storeItem.Title)}`} className="product-button btn2">Load More</Link>

            </div>

      </div>
    </div>

      </>
    );
  }
}

export default (props) => {
  const navigate = useNavigate();
  return <Card {...props} navigate={navigate} />;
};


