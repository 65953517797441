import Firm from "./Firm";
import Lawyer from "./Lawyer";
import Book from "./Book";
// import { StringsAr } from "./Strings.ar";
// import { StringsEn } from "./Strings.en";
// import { StringsHi } from "./Strings.hi";
import { SiteService } from "../services/site";
import ConfigCache from "./ConfigCache";
import { StringsEn } from "../language/String.en";
import { StringsHi } from "../language/String.hi";
import { StringsAr } from "../language/String.ar";
import Validation from "./Validation";
import { split } from "postcss/lib/list";
import { CartService } from "../services/cart";

class Catalog {
  static __strings = {
    en: StringsEn,
    hi: StringsHi,
    ar: StringsAr,
  };
  static __fields = {
    name: ["required"],
    first_name: ["required", "min:3"],
    last_name: ["required"],
    email: ["required", "email"],
    mobile: ["required", "mobile"],
    currentPassword: ["required", "min:6"],
    password: ["required", "min:6"],
    cpassword: ["required", "min:6", "confirm:password"],
    accept_legal_agreement: ["required"],
  };
  static __lawyer = Lawyer;
  static __firm = Firm;
  static __book = Book;
  static __lc = "en";
  static __token = null;
  static __is_load = false;
  static __rediarectLogin = "/account";
  static __message = null;
  static __flash = {}
  static async init() {
    Catalog.Lawyer.Cache = new ConfigCache("lawyers");
    Catalog.Firm.Cache = new ConfigCache("firms");
    Catalog.Book.Cache = new ConfigCache("books");
    if (Catalog.Session.isLogined) {
      const auth = await SiteService.authCheck();
      if (!auth || !auth.id) {
        Catalog.Session = {};
      }
    }
    let today = new Date().toDateString();
    // if (!Catalog.lastRefresh || Catalog.lastRefresh !== today) {
      await this.refetch();
      Catalog.lastRefresh = today;
    // }
    if (!Catalog.Cart.id) {
      Catalog.resetCart();
    }
    await this.refreshCart();
  }
  static async refreshCart() {
    if (Catalog.Session.isLogined) {
      const cart=await CartService.fetchCartItem(Catalog.Session.user.id,Catalog.Cart);
      Catalog.Cart=cart;
    }
  }
  static async updateCartUser(user){
    const cart=await CartService.updateCartUser(user.id,Catalog.Cart);
    // Catalog.Cart=cart;
  }
  static async removeFromCart(id) {
    try {
      const removeItems=(Catalog.Cart.items || []).filter((item) => item.id === id);
      if(removeItems.length>0){
        const removeItem = await CartService.RemovetoCart(removeItems[0].cart_id) 
        Catalog.Cart={
          ...Catalog.Cart,
          items:[
            ...(Catalog.Cart.items||[]).filter((item)=> item.id!==id),
          ]
        }  
      }
    } catch (error) {
      
    }
  }
  static async updateCartCount(id,count) {
    try {        
      const items=(Catalog.Cart.items || []).filter((item) => item.id === id);
      if(items.length>0){        
        const { cart } = await CartService.CreateCart(items[0].id,count); 
        if(cart){
            Catalog.Cart = {
              ...Catalog.Cart,
              items: [
                {
                  ...items[0],
                  count: count,
                  cart_id:cart.id
                },
                ...(Catalog.Cart.items || []).filter((item) => item.id !== id),
              ]
            }
        }
      } 
    } catch (error) {
      
    }
  }
  static async addToCart(storeItem) {
    try {        
      const items=(Catalog.Cart.items || []).filter((item) => item.id === storeItem.id);
      const qty = items.length === 0 ? 1:items[0]?.count||1;
      const { cart } = await CartService.CreateCart(storeItem.id,qty); 
      if(cart){
          Catalog.Cart = {
            ...Catalog.Cart,
            items: [
              {
                ...storeItem,
                count: qty,
                cart_id:cart.id
              },
              ...(Catalog.Cart.items || []).filter((item) => item.id !== storeItem.id),
            ]
          }
      }
    } catch (error) {
      
    }
  }
  static resetCart() {
    Catalog.Cart = {
      id: `CART${new Date().getTime()}`,
      items: []
    }
  }
  static async refetch() {
    await Promise.all([
      new Promise(async (resolve, reject) => {
        let page1 = 1;
        let pro1 = {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        do {
          pro1 = await SiteService.fetchStates(page1);
          await Catalog.Lawyer.Cache.refetch(
            "Property1",
            pro1.data,
            (Property1) => {
              return { id: Property1.id, name: Property1.name };
            }
          );
          await Catalog.Firm.Cache.refetch(
            "Property1",
            pro1.data,
            (Property1) => {
              return { id: Property1.id, name: Property1.name };
            }
          );
          page1++;
        } while ((pro1.pagination.pageCount - page1) >= 0);
        resolve("success");
      }),
      new Promise(async (resolve, reject) => {
        let page2 = 1;
        let pro2 = {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        do {
          pro2 = await SiteService.fetchCities(page2);
          await Catalog.Lawyer.Cache.refetch(
            "Property2",
            pro2.data,
            (Property2) => {
              return {
                id: Property2.id,
                name: Property2.name,
                state: Property2.state.id,
                district: Property2.district.id,
              };
            }
          );
          await Catalog.Firm.Cache.refetch(
            "Property2",
            pro2.data,
            (Property2) => {
              return {
                id: Property2.id,
                name: Property2.name,
                state: Property2.state.id,
                district: Property2.district.id,
              };
            }
          );
          page2++;
        } while ((pro2.pagination.pageCount - page2) >= 0);
        resolve("success");
      }),
      new Promise(async (resolve, reject) => {
        let page3 = 1;
        let pro3 = {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        do {
          pro3 = await SiteService.fetchSpecializations(page3);
          await Catalog.Lawyer.Cache.refetch(
            "PropertyList1",
            pro3.data,
            (PropertyList1) => {
              return { id: PropertyList1.id, name: PropertyList1.name };
            }
          );
          await Catalog.Firm.Cache.refetch(
            "PropertyList1",
            pro3.data,
            (PropertyList1) => {
              return { id: PropertyList1.id, name: PropertyList1.name };
            }
          );
          page3++;
        } while ((pro3.pagination.pageCount - page3) >= 0);
        resolve("success");
      }),
      new Promise(async (resolve, reject) => {
        let page4 = 1;
        let pro4 = {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        do {
          pro4 = await SiteService.fetchCourts(page4);
          await Catalog.Lawyer.Cache.refetch(
            "PropertyList2",
            pro4.data,
            (PropertyList2) => {
              return {
                id: PropertyList2.id,
                name: PropertyList2.name,
                state: PropertyList2.state.id,
                district: PropertyList2.district.id,
                city: PropertyList2.city.id,
              };
            }
          );
          await Catalog.Firm.Cache.refetch(
            "PropertyList2",
            pro4.data,
            (PropertyList2) => {
              return {
                id: PropertyList2.id,
                name: PropertyList2.name,
                state: PropertyList2.state.id,
                district: PropertyList2.district.id,
                city: PropertyList2.city.id,
              };
            }
          );
          page4++;
        } while ((pro4.pagination.pageCount - page4) >= 0);
        resolve("success");
      }),
      new Promise(async (resolve, reject) => {
        let page5 = 1;
        let pro5 = {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        do {
          pro5 = await SiteService.fetchLanguages(page5);
          await Catalog.Lawyer.Cache.refetch(
            "PropertyList3",
            pro5.data,
            (PropertyList3) => {
              return { id: PropertyList3.id, name: PropertyList3.name };
            }
          );
          await Catalog.Firm.Cache.refetch(
            "PropertyList3",
            pro5.data,
            (PropertyList3) => {
              return { id: PropertyList3.id, name: PropertyList3.name };
            }
          );
          page5++;
        } while ((pro5.pagination.pageCount) - page5 >= 0);
        resolve("success");
      }),
      new Promise(async (resolve, reject) => {
        let page6 = 1;
        let pro6 = {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        do {
          let pro6 = await SiteService.fetchTags(page6);
          await Catalog.Book.Cache.refetch(
            "PropertyList1",
            pro6.data,
            (PropertyList1) => {
              return { id: PropertyList1.id, name: PropertyList1.name };
            }
          );
          page6++;
        } while ((pro6.pagination.pageCount - page6) >= 0);
        resolve("success");
      }),
      new Promise(async (resolve, reject) => {
        let page7 = 1;
        let pro7 = {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        do {
          pro7 = await SiteService.fetchCategories(page7);
          await Catalog.Book.Cache.refetch(
            "Category",
            pro7.data,
            (Category) => {
              return { id: Category.id, name: Category.name };
            }
          );
          page7++;
        } while ((pro7.pagination.pageCount - page7) >= 0);
        resolve("success");
      }),
      new Promise(async (resolve, reject) => {
        let page8 = 1;
        let pro8 = {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        do {
          pro8 = await SiteService.fetchDistricts(page8);
          await Catalog.Lawyer.Cache.refetch(
            "Property3",
            pro8.data,
            (Property3) => {
              return {
                id: Property3.id,
                name: Property3.name,
                state: Property3.state.id,
              };
            }
          );
          await Catalog.Firm.Cache.refetch(
            "Property3",
            pro8.data,
            (Property3) => {
              return {
                id: Property3.id,
                name: Property3.name,
                state: Property3.state.id,
              };
            }
          );
          page8++;
        } while ((pro8.pagination.pageCount - page8) >= 0);
        resolve("success");
      }),

      new Promise(async (resolve, reject) => {
        let page9 = 1;
        let pro9 = {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        do {
          pro9 = await SiteService.fetchBookAuthors(page9);
          await Catalog.Book.Cache.refetch(
            "author_names",
            pro9.data,
            (author_names) => {
              return { id: author_names.id, name: author_names.AuthorName };
            }
          );
          page9++;
        } while ((pro9.pagination.pageCount - page9) >= 0);
        resolve("success");
      }),

      new Promise(async (resolve, reject) => {
        let page10 = 1;
        let pro10 = {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        do {
          pro10 = await SiteService.fetchBookPublisher(page10);
          await Catalog.Book.Cache.refetch(
            "publishers",
            pro10.data,
            (publishers) => {
              return { id: publishers.id, name: publishers.name };
            }
          );
          page10++;
        } while ((pro10.pagination.pageCount - page10) >= 0);
        resolve("success");
      }),

    ]);
  }
  static async refreshConfig() {
    this.Lawyer.Config.Property1 = (
      await this.Lawyer.Cache.getAll("Property1", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id };
    });
    this.Firm.Config.Property1 = (
      await this.Firm.Cache.getAll("Property1", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id };
    });
    this.Book.Config.Property1 = (
      await this.Book.Cache.getAll("Property1", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id };
    });
    this.Lawyer.Config.Property2 = (
      await this.Lawyer.Cache.getAll("Property2", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id, state: v.data.state || null, district: v.data.district || null };
    });
    this.Firm.Config.Property2 = (
      await this.Firm.Cache.getAll("Property2", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id, state: v.data.state || null, district: v.data.district || null };
    });
    this.Book.Config.Property2 = (
      await this.Book.Cache.getAll("Property2", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id, state: v.data.state || null, district: v.data.district || null };
    });
    this.Lawyer.Config.Property3 = (
      await this.Lawyer.Cache.getAll("Property3", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id, state: v.data.state || null, district: v.data.district || null };
    });
    this.Firm.Config.Property3 = (
      await this.Firm.Cache.getAll("Property3", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id, state: v.data.state || null, district: v.data.district || null };
    });
    this.Book.Config.Property3 = (
      await this.Book.Cache.getAll("Property3", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id, state: v.data.state || null, district: v.data.district || null };
    });

    this.Lawyer.Config.PropertyList1 = (
      await this.Lawyer.Cache.getAll("PropertyList1", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id };
    });
    this.Firm.Config.PropertyList1 = (
      await this.Firm.Cache.getAll("PropertyList1", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id };
    });
    this.Book.Config.PropertyList1 = (
      await this.Book.Cache.getAll("PropertyList1", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id };
    });

    this.Lawyer.Config.PropertyList2 = (
      await this.Lawyer.Cache.getAll("PropertyList2", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id, state: v.data.state || null, district: v.data.district || null, city: v.data.city || null };
    });
    this.Firm.Config.PropertyList2 = (
      await this.Firm.Cache.getAll("PropertyList2", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id, state: v.data.state || null, district: v.data.district || null, city: v.data.city || null };
    });
    this.Book.Config.PropertyList2 = (
      await this.Book.Cache.getAll("PropertyList2", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id, state: v.data.state || null, district: v.data.district || null, city: v.data.city || null };
    });

    this.Lawyer.Config.PropertyList3 = (
      await this.Lawyer.Cache.getAll("PropertyList3", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id };
    });
    this.Firm.Config.PropertyList3 = (
      await this.Firm.Cache.getAll("PropertyList3", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id };
    });
    this.Book.Config.PropertyList3 = (
      await this.Book.Cache.getAll("PropertyList3", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id };
    });

    this.Book.Config.Category = (
      await this.Book.Cache.getAll("Category", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id };
    });

    this.Book.Config.author_names = (
      await this.Book.Cache.getAll("author_names", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id };
    });

    this.Book.Config.publishers = (
      await this.Book.Cache.getAll("publishers", this.LC)
    ).map((v, i) => {
      return { label: v.data.name, value: v.data.id };
    });

  }
  static get Strings() {
    return this.__strings[this.__lc] || this.__strings["default"];
  }
  static set Strings(strings) {
    this.__strings[this.__lc] = strings;
  }
  static get Cart() {
    return JSON.parse(localStorage.getItem("__cart_session")) || {};
  }
  static set Cart(cart) {
    localStorage.setItem("__cart_session", JSON.stringify(cart || {}));
  }
  static get Flash() {
    return this.__flash||{};
  }
  static set Flash(flash) {
    this.__flash = flash;
  }
  static get isLoad() {
    return this.__is_load;
  }
  static set isLoad(isload) {
    this.__is_load = isload;
  }
  static get RediarectLogin() {
    const redirect = this.__rediarectLogin;
    this.__rediarectLogin = "/account";
    return redirect;
  }
  static set RediarectLogin(rediarectLogin) {
    this.__rediarectLogin = rediarectLogin;
  }

  static get Message() {
    const message = this.__message;
    this.__message = null;
    return message;
  }
  static get isMessage() {
    return this.__message ? true : false;
  }
  static set isMessage(ismessage) { }
  static set Message(message) {
    this.__message = message;
  }

  static get Session() {
    return JSON.parse(localStorage.getItem("_session")) || {};
  }
  static set Session(session) {
    localStorage.setItem("_session", JSON.stringify(session || {}));
  }
  static get lastRefresh() {
    return localStorage.getItem("_last_refresh_date") || null;
  }
  static set lastRefresh(date) {
    localStorage.setItem("_last_refresh_date", date);
  }
  static get NewsView() {
    return JSON.parse(localStorage.getItem("__newsview")) || {};
  }
  static set NewsView(newsview) {
    localStorage.setItem("__newsview", JSON.stringify(newsview || {}));
  }
  static get Lawyer() {
    return this.__lawyer;
  }
  static set Lawyer(lawyer) {
    this.__lawyer = lawyer;
  }
  static get Firm() {
    return this.__firm;
  }
  static set Firm(firm) {
    this.__firm = firm;
  }
  static get Book() {
    return this.__book;
  }
  static set Book(books) {
    this.__book = books;
  }
  static get LC() {
    return this.__lc;
  }
  static set LC(lc) {
    this.__lc = lc;
  }
  static get Token() {
    return this.__token;
  }
  static set Token(token) {
    this.__token = token;
  }
  static async validateUniqueEmail(email) {
    if (await SiteService.checkEmailExist(email)) {
      return {
        valid: false,
        message: Catalog.Strings.messages.uniqueuser("email"),
      };
    }
    return null;
  }
  static validate(field, value, ...args) {
    if (Object.prototype.hasOwnProperty.call(this.__fields, field)) {
      for (let index = 0; index < this.__fields[field].length; index++) {
        const r = this.__fields[field][index];
        const [rule, param] = r.split(":");
        const arg = [param, ...args];
        const result = Validation.validate(field, value, rule, ...arg);
        if (result.valid === false) {
          return result;
        }
      }
    }
    return null;
  }
}
export default Catalog;
