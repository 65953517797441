import { request } from "./../config/request"
import { API_ENDPOINT } from "../config/constant";
import Catalog from "../Catalog";
import noimagebook from "./../images/book.png";

export const CartService = {

  async CreateCart(book_id,quantity) {
    try {

      const existingCartResponse = await request.get(`/api/carts`, {
        params: {
          filters: {
            Batch_id: Catalog.Cart.id,
            catalog_books: book_id,
          }
        }
      });

      const existingCarts = existingCartResponse.data;

      if (existingCarts.data.length > 0) { 
        if (Catalog.Session.isLogined) {
        const updateResponse = await request.put(`/api/carts/${existingCarts.data[0].id}`, {
          data: {
            users_permissions_user: Catalog.Session.user.id,
            Quantity: quantity,
            Batch_id: Catalog.Cart.id
          }
        });
        return { status: 200, cart: updateResponse.data.data };
      }else{
        
        const updateResponse = await request.put(`/api/carts/${existingCarts.data[0].id}`, {
          data: {
            Quantity: quantity,
            Batch_id: Catalog.Cart.id
          }
        });
        return { status: 200, cart: updateResponse.data.data };
      }

      }
      else {
        if (Catalog.Session.isLogined) {
          const response = await request.post('/api/carts', {
            data: {
              users_permissions_user: Catalog.Session.user.id,
              catalog_books: book_id,
              Quantity: 1,
              Batch_id: Catalog.Cart.id
            }
          });

          if (response.status >= 200&&response.status<=204) {
            return { status: 200, cart: response.data.data };
          }else{
            return { error: "Failed to create or update cart." };
          }
        } else {
          const response = await request.post('/api/carts', {
            data: {
              catalog_books: book_id,
              Quantity: quantity,
              Batch_id: Catalog.Cart.id
            }
          });

          if (response.status >= 200&&response.status<=204) {
            return { status: 200, cart: response.data.data };
          }else{
            return { error: "Failed to create or update cart." };
          }
        }
      }
    } catch (error) { 
      return { error: "Failed to create or update cart." };
    }
  },
  async updateCartUser(userId,cartData){  
    const newCart = await CartService.fetchCartItem(userId,cartData);     
    Catalog.Cart=newCart; 
    for (let index = 0; index < cartData.items.length; index++) {
      const item = cartData.items[index];
      this.CreateCart(item.id,item.count)
      // this.CreateCart(item.id,item.count)
      // this.RemovetoCart(item.cart_id)
      // await request.put(`/api/carts/${item.cart_id}`, {
      //   data: {
      //     users_permissions_user: userId,
      //     Batch_id: newCart.id
      //   }
      // });
    }   
    Catalog.Cart=newCart; 
    return newCart;
  },
  async fetchCartItem(userId,cartData) {
    cartData.items=[];
    try {
      const response = await request.get(`/api/carts?populate[catalog_books][populate][PrimaryImageId]=true&populate[catalog_books][populate][author_names]=true&populate[catalog_books][populate][publishers]=true&populate[catalog_books][populate][SecondaryImageIds]=true&populate[catalog_books][populate][Category]=true&filters[users_permissions_user][id][$eq]=${userId}`); 
   
      if (response.status === 200) {
        if(response.data.data.length>0){
          const cartItems = response.data.data.map(cart => {
            const { id: cartId ,attributes:{ catalog_books ,Batch_id,Quantity} } = cart;
            const storeItems = catalog_books.data[0];
            cartData.id=Batch_id;
            return {
              id: storeItems.id,
              author_names: storeItems.attributes.author_names, 
              image: storeItems.attributes.PrimaryImageId.data ? API_ENDPOINT + storeItems.attributes.PrimaryImageId.data.attributes.url : noimagebook,
              ...storeItems.attributes,
              Category: storeItems.attributes.Category, 
              cart_id:cartId,
              count:Quantity
            }
          });
          cartData.items=cartItems;
        }

      } else {
        throw new Error("Error fetching carts");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error); 
    }
    return cartData
  },


  async RemovetoCart(CartId) {
    try {

      const response = await request.delete(`/api/carts/${CartId}`);

      if (response.status >= 200&&response.status<=204) {

        return response.data;

      } else {

        throw new Error("Error removing item from cart");

      }

    } catch (error) {

      console.error(error);

      return { error: "Failed to remove item from cart." };
    }
  },

};