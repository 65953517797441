import React from 'react'
import "./../css/Lawer.css";
import FindLawerController from '../controllers/FindLawerController'
const FindALawer = () => {
  return (
    <div>
        <FindLawerController></FindLawerController>
    </div>
  )
}


export default FindALawer
