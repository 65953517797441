import React, { useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {SiteService} from "../../services/site"
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import toast from 'react-hot-toast';
const schema = yup.object({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    email: yup.string().email('Invalid email format').required('Email is required'),
    mobile: yup.string().required('Mobile number is required').test('mobile-length', 'Mobile number must be 10 digits', (value) => {
        return value && /^\d{10}$/.test(value);
    }),
    password: yup.string().min(6,'Password must be at least 6 characters').required('Password is required'),
    cpassword: yup.string().required('Confirm password is required').oneOf([yup.ref('password'), null], 'Passwords must match'),
    role: yup.object().shape({
                  label: yup.string().required('Role is required'),
                  value: yup.string().required('Role is required'),
                }),
    accept: yup.bool().when('role', {
        is: '4', // The value of 'role' that triggers the condition
        then: () => yup.bool().oneOf([true], 'Accept Legal Agreement'), // Required when role is '4'
        otherwise:() => yup.bool().nullable() // No validation when role is not '4'
      })
});
const BasicUserFrom = ({setStep, setFormData, data, settings}) => {
    const navigate = useNavigate();
    


    const { register, handleSubmit, formState: { errors },control, reset, watch } = useForm({
        defaultValues: {
            first_name: data?.first_name || '',
            last_name: data?.last_name || '',
            email: data?.email || '',
            mobile: data?.mobile || '',
            password:  data?.password || '',
            cpassword: data?.cpassword ||  '',
            role: data?.role || { value: '4', label: 'Individual User' },
            accept: data?.accept ||  false
        },
        resolver: yupResolver(schema),
      });
    
      const onSubmit = async (data) => {
        data.username =
        (data.first_name || "") +
        "-" +
        (data.last_name || "") +
        "-" +
        new Date().getTime().toString();
        setFormData(data);
        if(data?.role?.value !== '4'){
            setStep(2);
        }else{
            await SiteService.registerUser({...data,role:data.role.value}, settings).then(res=>{
                toast.success('Registered successfully');
                navigate('/login')
                return res;
            }).catch(err=>{
                toast.error(err?.response?.data?.error?.message);
            });
            
        }
     
        
      };
      const options = [
        { value: '4', label: 'Individual User' },
        { value: '5', label: 'Individual Lawyer' },
        { value: '6', label: 'Firm' },
      ];
      const loadOptions = (inputValue, callback) => {
        const filteredOptions = options.filter((option) =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        callback(filteredOptions);
      };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className='signInForm'>
    <div className="input-field">
        <Form.Group className="mb-3"  controlId="formBasicFirstName">
        <Form.Label>First Name*</Form.Label>
        <Form.Control type="text" {...register('first_name')} />
        {errors.first_name && <Form.Text className="text-danger">{errors.first_name.message}</Form.Text>}
        </Form.Group>
    </div>
    <div className="input-field">
        <Form.Group className="mb-3" controlId="formBasicLastName">
        <Form.Label>Last Name*</Form.Label>
        <Form.Control type="text" {...register('last_name')} />
        {errors.last_name && <Form.Text className="text-danger">{errors.last_name.message}</Form.Text>}
        </Form.Group>
    </div>
    <div className="input-field">
        <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address*</Form.Label>
        <Form.Control type="email" {...register('email')} />
        {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
        </Form.Group>
    </div>
    <div className="input-field">
        <Form.Group className="mb-3" controlId="formBasicMobile">
        <Form.Label>Mobile*</Form.Label>
        <Form.Control type="text" {...register('mobile')} />
        {errors.mobile && <Form.Text className="text-danger">{errors.mobile.message}</Form.Text>}
        </Form.Group>
    </div>    
    <div className="input-field">
          <Form.Group className="mb-3" controlId="formBasicUserType">
              <Form.Label>User Type</Form.Label>
              <InputGroup hasValidation>
              <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                        <AsyncSelect  {...field} 
                        
                        cacheOptions
                        loadOptions={loadOptions}
                        defaultOptions={options}
                        placeholder="Select a user type"
                        />
                    )}
                    />
                  
                  {errors.role && <Form.Text className="text-danger">{errors.role.message}</Form.Text>}
              </InputGroup>
          </Form.Group> 
    </div>
    <div className="input-field">
        <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password*</Form.Label>
        <Form.Control type="password" {...register('password')} />
        {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
        </Form.Group>
    </div>
    <div className="input-field">
        <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
        <Form.Label>Confirm Password*</Form.Label>
        <Form.Control type="password" {...register('cpassword')} />
        {errors.cpassword && <Form.Text className="text-danger">{errors.cpassword.message}</Form.Text>}
        </Form.Group>
    </div>
    {/* <div className="input-field">
        <Form.Group className="mb-3" controlId="formBasicUserType">
        <Form.Check value="4" id="userType_basic" type="radio" label="Individual User" {...register('role')}  />
        <Form.Check value="5" id="userType_lawyer" type="radio" label="Individual Lawyer" {...register('role')}  />
        <Form.Check value="6" id="userType_firm" type="radio" label="Firm" {...register('role')} />
        {errors.role && <Form.Text className="text-danger">{errors.role.message}</Form.Text>}
        </Form.Group>
    </div> */}
    {watch("role")?.value === '4' && <>
        <br/>
        <div className="input-field">
            <Form.Group className="mb-3" controlId="formBasicUserType">
            <Form.Check value={true} id="userType_agreement" type="checkbox" label="Please click the checkbox to accept the Legal Agreement." {...register('accept')}  />
            {errors.accept && <Form.Text className="text-danger">{errors.accept.message}</Form.Text>}
            </Form.Group>
        </div>
    </>}
    
    <div className="formfield">
    <Button
        variant="primary"
        className="signInBtn1"
        type="submit"
    >
        {watch("role")?.value === "4" ? "Submit" : "Next"}
    </Button>
</div>

   
  </Form>
  );
};

export default BasicUserFrom;