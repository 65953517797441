import React, { Component } from "react";
import Catalog from "../Catalog";
import { AppContext, titleSlug } from "../config/common";
import { Link, Navigate } from "react-router-dom";
import { StoreService } from "../services/store";

class FindLawyerController extends Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            isloaded: false,
            redirectToLawyers: false,
            availableFilterData: { 
              PropertyList1:[],
              PropertyList2:[],
              PropertyList3:[],
              Property1:[],
              Property2:[],
              Category:[],
              author_names:[],
              publishers:[],  
              SubTitle:[],
              Price:[],
              Binding:[],
              Type:[],
              Search:[],             
            },
        }
    }
    componentDidMount() {
        document.body.classList.add('lawyerclass');
        this.fetchFilterIds();
        this.intervalId = setInterval(this.refreshlist, 1000);
    }

    componentWillUnmount() {
        document.body.classList.remove('lawyerclass');
        clearInterval(this.intervalId);
    }
    fetchFilterIds = async () => { 
        const availableFilterData = await StoreService.fetchFilterIds('lawyers'); 
        this.setState({ availableFilterData });
    }

    refreshlist = async () => {
        if (!this.state.isloaded && Catalog.isLoad) {
            await Catalog.refreshConfig();
        }
        const isloaded = Catalog.isLoad;
        this.setState({ isloaded })
    }


    render() {
        if (this.state.redirectToLawyers) {
            return <>
                <Navigate replace to={'/catalogs/lawyers'} />
            </>
        }
        if (this.state.isloaded == true) {
            return <div className="find-wrap">
                <h2>See lawyers by practice area</h2>
                <p>Choose an area of law to find top-rated Lawyers near you.</p>
                <div className='lawer-ul-sec'>
                    <div className='lawer-1-sec l-te'>
                        <ul className="lawer-list">
                            {[...(Catalog.Lawyer.Config.PropertyList1 || [])].filter((itm)=>(this.state.availableFilterData.PropertyList1||[]).includes(itm.value)).map((item, index) => (
                                <li key={index} className="lawer-item">
                                    <Link to={'/find-a-lawyer/' + titleSlug(item.label)}>{item.label}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* <Link onClick={() => { }} className="practice-area"  >See all pratice area</Link> */}
                    <div className='lawer-2-sec l-te'>
                        <h2>View Lawyers by state</h2>

                        <ul className="lawer-list">
                            {[...(Catalog.Lawyer.Config.Property1 || [])].filter((itm)=>(this.state.availableFilterData.Property1||[]).includes(itm.value)).map((item, index) => (
                                <li key={index} className="lawer-item">
                                    <Link onClick={() => {
                                        Catalog.Flash = {
                                            ...Catalog.Flash,
                                            Property1: [item.value + ""]
                                        }
                                        this.setState({
                                            redirectToLawyers: true
                                        })
                                    }}>{item.label}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='lawer-3-sec l-te'>
                        <h2>View Lawyers by city</h2>

                        <ul className="lawer-list">
                            {[...(Catalog.Lawyer.Config.Property2 || [])].filter((itm)=>(this.state.availableFilterData.Property2||[]).includes(itm.value)).map((item, index) => (
                                <li key={index} className="lawer-item">
                                    <Link onClick={() => {
                                        Catalog.Flash = {
                                            ...Catalog.Flash,
                                            Property2: [item.value + ""]
                                        }
                                        this.setState({
                                            redirectToLawyers: true
                                        })
                                    }}>{item.label}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='lawer-sub-sec'>
                    <h4>How to find and hire a great lawyer</h4>
                    <h5>Do I need a lawyer?</h5>
                    <div className="law-text">
                        <h6>What do lawyers do, exactly?</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    </div>
                    <div className="law-text">
                        <h6>What do lawyers do, exactly?</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    </div>
                </div>
            </div>;
        }
        else {
            return <>Loading</>
        }
    }
}
export default FindLawyerController;