import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
 
import { titleSlug } from "../config/common";
import { Spinner } from "react-bootstrap";
import Catalog from '../Catalog/index';  // Adjusted to explicitly point to the index.js file


import d1 from "./../images/d1.png";


import "./../css/widget/SearchCard.css";
class Prodcard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: props.news,
      active: props.active,
      ...props,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      news: props.news,
      active: props.active,
    };
  }
 
  handleAddToCart = async (e) => {
    e.preventDefault(); 
    const { similaritem } = this.state;
  
    console.log(this.state.similaritem,'aluva');

    if (!similaritem) {
      console.error("Store item not set. Cannot add to cart.");
      return;
    }
  
    if (!this.state.isloading) {
      this.setState({ isloading: true });
      try {
        const result = await Catalog.addToCart(similaritem);
        if (result) {
          console.log("Item added to cart successfully.");
        } else {
          console.error("Failed to add item to cartmkk.");
        }
      } catch (error) {
        console.error("Error while adding item to cart:", error);
      } finally {
        this.setState({ isloading: false });
      }
    }
  };
  
  componentDidMount() {

   this.intervalId=setInterval(this.refreshlist, 1000); 
   
    const storeItem = {
      id: this.state.id,
      name: this.state.name,
      price: this.state.np,
      discount: this.state.discount,
      image: this.state.img?.data?.attributes?.url,
    };
  
    this.setState({ storeItem });
  }

  refreshlist=async ()=>{ 
   
    if(!this.state.isloaded &&Catalog.isLoad){
      await Catalog.refreshConfig();
    }
    const isloaded=Catalog.isLoad;
    this.setState({isloaded})
  }


  componentWillUnmount() {
   
    clearInterval(this.intervalId);
  }
  render() {

    let cartitems = (Catalog.Cart.items||[]).filter((item)=> item.id===this.state.id)||[];
    const data=this.state;

    // console.log(Catalog.Cart.items,'data');
    // console.log(this.state.id,'keyboard');
    return (
        
      <div className="product-card">
      <img src={data.img.data?.attributes?.url} alt="Book Cover" />
      <div className="product-info">
        <h2 className="product-title">{data.name}</h2>
    
        {data.Author && data.Author.data ? (
          data.Author.data.map((item, i) => (
            <p key={i} className="product-author">{item.attributes.AuthorName}</p>
          ))
        ) : (
          null
        )}
    
        <p className="product-description">{data.description}</p>
        <div className="product-price">
  {data.discount > 0 && data.Op > 0 ? (
    <>
      {/* <span className="product-price-current">
        ₹{(data.Op * (100 - data.discount)) / 100}
      </span> */}

      {/* <span className="product-price-original">
        ₹{data.Op}
      </span> */}

      <span className="product-price-offer">
        {data.discount}% Offer
      </span>
    </>
  ) : (
    <>
      {/* <span className="product-price-current">
        ₹{data.Op || data.np} 
      </span> */}
    </>
  )}
</div>




<div className="product-actions">
  {/* {this.state.storeItem && this.state.isloaded&& (
    <Link
      className="product-button btn1"
      onClick={this.handleAddToCart}
    >
      Search Books
      {cartitems.length > 0 && <>({cartitems[0].count})</>}
      {this.state.isloading && <Spinner size="sm" />}
    </Link>
  )} */}
  <Link
    to={`/catalogs/books/`}
    className="product-button btn1"
  >
    Search
  </Link>
</div>

      </div>
    </div>
    
        
      );
  }
}
export default Prodcard;
