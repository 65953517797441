import React, { Component } from "react";
import FormField from "../widgets/FormField";
import RecentNews from "../widgets/RecentNews";
import SideBarAdCard from "../widgets/SideBarAdCard";
import { Button, Form, Alert } from "react-bootstrap";
import Prodcard from "../widgets/Prodcard";


import { SiteService } from "./../services/site";
import d1 from "./../images/d1.png";

class ComingSoonController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      categorys: [],
      formData: {
        email: null,
      },
      errorData: {
        email: null,
      },
      subscriptionerror: null,
      subscriptionsuccess: null,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let formData = this.state.formData;
    let errorData = {};
    let isInValid = false;
    for (const name in formData) {
      errorData[name] = "The " + name + " field is required";
      if (Object.hasOwnProperty.call(formData, name)) {
        if (formData[name] && formData[name] !== "") {
          errorData[name] = null;
        } else {
          isInValid = true;
        }
      } else {
        isInValid = true;
      }
    }
    let subscriptionerror = null;
    this.setState({ errorData, subscriptionerror });
    if (!isInValid) {
      const subscription = await SiteService.subscribeComingSoon(formData);
      if (subscription) {
        formData = {
          email: null,
        };
        errorData = {
          email: null,
        };
        const subscriptionsuccess =
          "Thank you for subscribing. We look forward to staying in touch and bringing you great content!";
        this.setState({ formData, errorData, subscriptionsuccess });
      } else {
        if (subscription.error) {
          subscriptionerror = subscription.error.message;
          this.setState({ subscriptionerror });
        }
      }
    }
    return !isInValid;
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const formData = {
      ...this.state.formData,
      [name]: value,
    };
    this.setState({ formData });
    if (!value || value === "") {
      const errorData = {
        ...this.state.errorData,
        [name]: "The " + name + " field is required",
      };
      this.setState({ errorData });
    } else {
      const errorData = {
        ...this.state.errorData,
        [name]: null,
      };
      this.setState({ errorData });
    }
  };
  render() {



    // const related = [
    //   {
    //     id: 1,
    //     name: "Books",
    //     img: d1,      
        
    //     description:
    //       "Starting the solids journey for your baby is an exciting journey but this can often feel like...",
    //   },
    
    //   {
    //     id: 2,
    //     name: "Lawyers",
    //     img: d1,
      
      
    //     description:
    //       "Starting the solids journey for your baby is an exciting journey but this can often feel like...",
    //   },
    //   {
    //     id: 3,
    //     name: "Firms",
    //     img: d1,
       
      
    //     description:
    //       "Starting the solids journey for your baby is an exciting journey but this can often feel like...",
    //   },
     
      
     
    // ];


    return (

      <div>
     
    

      <div className="home-page-outer">
        <div className="row row-two">
          <div className="col-md-9">
            <div className="contact-wrap">
              <div className="contact-row row">
                <div className="contact-col col-md-7 mr-auto">
                  <h2>Coming Soon...</h2>
                  <p>
                    Enter your email to know when this feature is available and
                    get some discounts and offers.
                  </p>
                  {this.state.subscriptionerror ? (
                    <Alert variant={"error"}>
                      {this.state.subscriptionerror}
                    </Alert>
                  ) : (
                    <></>
                  )}

                  {this.state.subscriptionsuccess ? (
                    <Alert variant={"success"}>
                      {this.state.subscriptionsuccess}
                    </Alert>
                  ) : (
                    <></>
                  )}
                  <Form onSubmit={this.handleSubmit} noValidate>
                    <FormField
                      placeholder={"Your Email"}
                      value={this.state.formData?.email}
                      error={this.state.errorData?.email}
                      name={"email"}
                      handle={this.handleChange}
                    />
                    <Button variant="primary" type="submit">
                      Subscribe Now
                    </Button>
                  </Form>
                </div>

{/* 
<h2>Products</h2>

<h5>A product description is a form of marketing copy used to describe and explain the benefits of your product. In other words, it provides all the information and details of your product on your ecommerce site. These product details can be one sentence, a short paragraph or bulleted. They can be serious, funny or quirky.</h5>


<div className="data">
        {related.map((item, i) => {
          return (
            <Prodcard
              key={i}
              id={item.id}
              name={item.name}
              img={item.img}
              Op={item.oldPrice}
              np={item.newPrice}
              Author={item.Author}
              description={item.description}
            />
          );
        })}
      </div> */}

                
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <SideBarAdCard></SideBarAdCard>
            <RecentNews></RecentNews>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default ComingSoonController;
