import md5 from "md5";

class ConfigCache {
  __name = "dev-dailylawtimes";
  __version = 1;
  __store = null;
  __storeFields = [
    "Property1",
    "Property2",
    "Property3",
    "PropertyList1",
    "PropertyList2",
    "PropertyList3",
    "Category",
    "author_names",
    "publishers",
  ];
  __lc = ["en", "hi", "ar"];
  __data = {}
  constructor(store) {
    this.__store = store;
  }

  async init() {
    this.__db = md5(`${this.__name}-${this.__store}`);
    this.__data[this.__db] = {};
    this.__storeFields.forEach((item)=>{
      this.__data[this.__db][item]=[];
    })
    // try {
    //   await new Promise((resolve, reject) => {
    //     const request = indexedDB.open(
    //       md5(`${this.__name}-${this.__store}`),
    //       this.__version
    //     );
    //     request.onupgradeneeded = (event) => {
    //       this.__db = event.target.result;
    //       this.__storeFields.forEach((element) => {
    //         if (!this.__db.objectStoreNames.contains(element)) {
    //           const objectstore = this.__db.createObjectStore(element, {
    //             keyPath: "id",
    //           });
    //           objectstore.createIndex("store", "store", { unique: false });
    //         }
    //       });
    //     };
    //     request.onsuccess = (event) => {
    //       this.__db = event.target.result;
    //       resolve();
    //     };

    //     request.onerror = (event) => {
    //       console.error("Database failed to initialize:", event.target.error);
    //       reject();
    //     };
    //   });
    // } catch (error) {}
  }
  async addData(id, data, type, lc = "en") {
    if (!this.__db) await this.init();
    let index = this.__data[this.__db][type].findIndex(item => item.id === `${id}-${lc}-${this.__store}`);
    if (index !== -1) {
      this.__data[this.__db][type]=this.__data[this.__db][type].map((item)=>{
        if (item.id ===`${id}-${lc}-${this.__store}`) {
          return {
            id: `${id}-${lc}-${this.__store}`,
            data: data,
            store: `${this.__store}-${lc}`,
          }; 
        }
        return item;
      })||[];
    }else{
      this.__data[this.__db][type].push({
          id: `${id}-${lc}-${this.__store}`,
          data: data,
          store: `${this.__store}-${lc}`,
      });
    }

    // try {
    //   await new Promise((resolve, reject) => {
    //     const transaction = this.__db.transaction([type], "readwrite");
    //     const objectStore = transaction.objectStore(type);
    //     const request = objectStore.add({
    //       id: `${id}-${lc}-${this.__store}`,
    //       data: data,
    //       store: `${this.__store}-${lc}`,
    //     });

    //     request.onsuccess = () => {
    //       resolve();
    //     };
    //     request.onerror = (event) => {
    //       console.error("Error adding pair:", event.target.error);
    //       reject();
    //     };
    //   });
    // } catch (error) {}
  }

  async addAll(datalist, type, lc = "en") {
    if (!this.__db) await this.init();
    datalist.forEach((item) => {     
      this.addData(item.id,item,type,lc) 
    })

    // const transaction = this.__db.transaction([type], "readwrite");
    // const objectStore = transaction.objectStore(type);
    // const dataPromises = datalist.forEach((item) => {
    //   return new Promise((resolve, reject) => {
    //     const request = objectStore.add({
    //       id: `${item.id}-${lc}`,
    //       data: item,
    //       store: `${this.__store}-${lc}`,
    //     });

    //     request.onsuccess = () => {
    //       resolve();
    //     };
    //     request.onerror = (event) => {
    //       console.error("Error adding pair:", event.target.error);
    //       reject();
    //     };
    //   });
    // });

    // try {
    //   await Promise.all(dataPromises);
    // } catch (error) { }
  }
  async getData(id, type, lc = "en") {
    if (!this.__db) await this.init();
    return this.__data[this.__db][type].find((item)=>item.id === `${id}-${lc}-${this.__store}`)||null;
    
    // try {
    //   return await new Promise((resolve, reject) => {
    //     const transaction = this.__db.transaction([type], "readonly");
    //     const objectStore = transaction.objectStore(type);
    //     const request = objectStore.get(`${id}-${lc}-${this.__store}`);

    //     request.onsuccess = (event) => {
    //       const results = event.target.result;
    //       if (results.length > 0) {
    //         resolve(results);
    //       } else {
    //         resolve(null);
    //       }
    //     };

    //     request.onerror = (event) => {
    //       reject(event.target.error);
    //     };
    //   });
    // } catch (error) {
    //   return null;
    // }
  }
  async getAll(type, lc = "en") {
    if (!this.__db) await this.init();
    return this.__data[this.__db][type].filter((item)=>item.store === `${this.__store}-${lc}`)||[];

    // try {
    //   return await new Promise((resolve, reject) => {
    //     const transaction = this.__db.transaction([type], "readonly");
    //     const objectStore = transaction.objectStore(type);
    //     const index = objectStore.index("store");
    //     const request = index.getAll(`${this.__store}-${lc}`);

    //     request.onsuccess = (event) => {
    //       const results = event.target.result;
    //       if (results.length > 0) {
    //         resolve(results);
    //       } else {
    //         resolve([]);
    //       }
    //     };

    //     request.onerror = (event) => {
    //       reject(event.target.error);
    //     };
    //   });
    // } catch (error) {
    //   return [];
    // }
  }
  async updateData(id, data, type, lc = "en") {
    if (!this.__db) await this.init(); 
    this.addData(id,data,type,lc) 
    // try {
    //   return await new Promise((resolve, reject) => {
    //     const transaction = this.__db.transaction([type], "readwrite");
    //     const objectStore = transaction.objectStore(type);
    //     const request = objectStore.put({
    //       id: `${id}-${lc}-${this.__store}`,
    //       data: data,
    //       store: `${this.__store}-${lc}`,
    //     });

    //     request.onsuccess = () => {
    //       resolve();
    //     };

    //     request.onerror = (event) => {
    //       console.error("Error updating pair:", event.target.error);
    //       reject(event.target.error);
    //     };
    //   });
    // } catch (error) { }
  }
  async refetch(type, datalist, filter) {
    if (!this.__db) await this.init();
    for (const key in datalist) {
      if (Object.prototype.hasOwnProperty.call(datalist, key)) {
        const item = datalist[key];
        const id = item.id;
        const data = filter(item);
        for (let index = 0; index < this.__lc.length; index++) {
          const lc = this.__lc[index];
          this.addData(id,data,type,lc) 
        //   try {
        //     await new Promise((resolve, reject) => {
        //       const transaction = this.__db.transaction([type], "readwrite");
        //       const objectStore = transaction.objectStore(type);
        //       const request = objectStore.put({
        //         id: `${id}-${lc}-${this.__store}`,
        //         data: data,
        //         store: `${this.__store}-${lc}`,
        //       });

        //       request.onsuccess = () => {
        //         resolve();
        //       };

        //       request.onerror = (event) => {
        //         console.error("Error updating pair:", event.target.error);
        //         reject(event.target.error);
        //       };
        //     });
        //   } catch (error) { }
        }
      }
    }
  }
  async deleteData(id, type, lc = "en") {
    if (!this.__db) await this.init();
    this.__data[this.__db][type]=this.__data[this.__db][type].filter((item)=>item.id !== `${id}-${lc}-${this.__store}`)||[]
    // try {
    //   return await new Promise((resolve, reject) => {
    //     const transaction = this.__db.transaction([type], "readwrite");
    //     const objectStore = transaction.objectStore(type);
    //     const request = objectStore.delete(`${id}-${lc}-${this.__store}`);
    //     request.onsuccess = () => {
    //       resolve();
    //     };

    //     request.onerror = (event) => {
    //       console.error("Error deleting  pair:", event.target.error);
    //       reject(event.target.error);
    //     };
    //   });
    // } catch (error) { }
  }
  async deleteAll(type) {
    if (!this.__db) await this.init();
    this.__data[this.__db][type]=[];
    // try {
    //   return await new Promise((resolve, reject) => {
    //     const transaction = this.__db.transaction([type], "readwrite");
    //     const objectStore = transaction.objectStore(type);

    //     const request = objectStore.clear();

    //     request.onsuccess = () => resolve();
    //     request.onerror = (event) => reject(event.target.error);
    //   });
    // } catch (error) { }
  }
}
export default ConfigCache;
