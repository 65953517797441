import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { AppContext } from "../config/common";
import { StoreService } from "../services/store";
import { PaymentService } from "../services/payment";
import Catalog from "../Catalog";
import { Link, Navigate } from "react-router-dom";
import { Alert, Badge, Button, Modal, Spinner } from "react-bootstrap";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { request } from "../config/request";
// import { Razorpay } from "razorpay"

class PaymentController extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      storeItem: {},
      storeName: "",
      storeId: "",
      storeSlug: "",
      loaded: false,
      modal: false,
      isSubmit: false,
      success: null,
      error: null,
      currentimage: null,
      currentImageIndex: 0,
      amount: "",
      order:[],
      Payments:{},
      account: {},
      accountFormData: {},
      profile: {},
      profileFormData: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchStoreItem();
    this.intervalId=setInterval(this.refreshlist, 1000); 
    this.initializeRazorpay()
    if (Catalog.Session.isLogined) {
      this.fetchAccount();
    }
  }

  componentDidUpdate() {
    if (
      this.state.store !== this.state.storeName ||
      this.state.id !== this.state.storeId ||
      this.state.slug !== this.state.storeSlug
    ) {
      this.fetchStoreItem();
    }

     if (
      Catalog.Session.isLogined &&
      Catalog.Session.user.id !== this.state.account.id
    ) {
      this.fetchAccount();
    }
  } 
 
  componentWillUnmount() { 
    clearInterval(this.intervalId);
    if(this.razorscript){
        document.body.removeChild(this.razorscript);
        this.razorscript=null;
    }
  }
  refreshlist=async ()=>{  
    const isloaded=Catalog.isLoad;
    this.setState({isloaded})
  }
  fetchAccount = async () => {
    const id = Catalog.Session.user.id;
    const account = await StoreService.fetchAccount(id);
    const accountFormData = {first_name:account.first_name,last_name:account.last_name};
    this.setState({ account, accountFormData });
    if (account.role.id === 5 || account.role.id===6) {
      this.fetchLawyer();
    } 
    if (account.role.id === 4) {
      this.fetchOrder();
    }
  };
  fetchOrder = async () => {
    const id = Catalog.Session.user.id;
    const orders = await StoreService.fetchOrder(id);
    this.setState({ orders });
  };

  fetchLawyer = async () => {
    const id = Catalog.Session.user.id;
    const profile = await StoreService.fetchLawyer(id); 
    const {PrimaryImageId,SecondaryImageIds,image,Category,...FormData}=profile;
    const profileFormData ={
      ...FormData,
      PrimaryImageId:PrimaryImageId?.data?.id??null,
      SecondaryImageIds:(SecondaryImageIds?.data||[]).map((im)=>im.id)
    }
    this.setState({ profile ,profileFormData}); 
  }; 
  handleClose = () => {
    const modal = false;
    this.setState({ modal });
  };
  ucfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  formatDateYMD = (date) => {
    if (!date) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  handleShow = () => {
    const modal = true;
    this.setState({ modal });
  };
  handleSubmit = async () => {
    let isSubmit = true;
    this.setState({ isSubmit });
    const settings = this.context;
    const orderdate = new Date();
    const expdate = new Date();
    expdate.setMonth(expdate.getMonth() + 1);
    const order = await StoreService.createOrder(
      {
        user_id: Catalog.Session.user.id,
        store_id: this.ucfirst(this.state.store),
        item_id: this.state.storeItem.id.toString(),
        purchase_date: this.formatDateYMD(orderdate),
        expiry_date: this.formatDateYMD(expdate),
        item_detail: this.state.storeItem,
        Price: this.state.storeItem.Price,
      },
      settings
    );
    let success,
      error = null;
    if (order.id || (order.data && order.data.id)) {
      success = "Order was placed";
    } else {
      error = "Error: plese try again later ";
    }

    const modal = false;
    isSubmit = false;
    this.setState({ modal, isSubmit, success, error });
  };

  fetchStoreItem = async () => {
    const storeName = this.state.store;
    const storeId = this.state.id;
    const storeSlug = this.state.slug;
    let loaded = false;
    this.setState({ storeName, storeId, storeSlug, loaded });
    const storeItem = await StoreService.fetchStoreItem(
      storeName,
      storeId,
      storeSlug
    );
    loaded = true;
    const currentImageIndex = 0;
    let currentimage = storeItem.image;
    if (this.state.store !== "books" && storeItem.gallery.length > 0) {
      currentimage = storeItem.gallery[0].image;
    }
    this.setState({ storeItem, loaded, currentimage, currentImageIndex });
  };


  
  handleChangeCurrentImage = (e) => {
    const { src: currentimage } = e.target;
    let currentImageIndex = 0;
    for (let index = 0; index < this.state.storeItem.gallery.length; index++) {
      if (this.state.storeItem.gallery[index].image === currentimage) {
        currentImageIndex = index + 1;
      }
    }
    this.setState({ currentimage, currentImageIndex });
    
  };

  

    initializeRazorpay=()=>{
        this.razorscript = document.createElement("script");
        this.razorscript.src = "https://checkout.razorpay.com/v1/checkout.js";
        this.razorscript.async = true;
        document.body.appendChild(this.razorscript);
    }

  
    payNow = async () => {
       console.log('Starting payment...'); 
       console.log(this.state.storeItem);
       const book_id=this.state.storeItem.id;
       const Price=this.state.storeItem.Price;
       const user_id=this.state.account.id;
       const profile=this.state.account;
       const bookname=this.state.storeItem.Title;
       const description=this.state.storeItem.ShortDescription;
       console.log(book_id);
       console.log(user_id);
       console.log(profile);
       const firstname=profile.first_name;
       const lastname=profile.last_name;
       const fullname=profile.first_name+' '+profile.last_name;
       const email=profile.email;
       const contact=profile.mobile;
    
        if(!window.Razorpay) return;
      try {
        console.log('console...');
        // const response = await request.post('https://techlegalonline.com/api/payment/payment', {data:{ user_id:user_id,book_id:book_id,Price:Price }});
        // const response = await request.post('http://localhost:1337/api/payment', {data:{ user_id:user_id,book_id:book_id,Price:Price }});
  
        // const data = await response.json();

        const payment=await PaymentService.submitPayment(user_id,book_id,Price);
console.log('test');
        console.log(payment);
      //   const data={
      //     "order": {
      //         "amount": 50000,
      //         "amount_due": 50000,
      //         "amount_paid": 0,
      //         "attempts": 0,
      //         "created_at": 1733205350,
      //         "currency": "INR",
      //         "entity": "order",
      //         "id": "order_PSazMWSX2O3nTt",
      //         "notes": [],
      //         "offer_id": null,
      //         "receipt": "TL1733205349417",
      //         "status": "created"
      //     }
      // }
      const order =payment.order;
    
        if (!order || !order.id) {
          throw new Error('Failed to create an order');
        } 
      
        const options = {
          key: 'rzp_test_6psStwFrJ906iD',
          amount: order.amount, 
          currency: order.currency,
          name: bookname,
          description: description,
          order_id: order.id, 
          callback_url: 'https://techlegalonline.com/api/payment/success', 
          prefill: {
            name: fullname,
            email: email,
            contact: contact,
          },
          theme: {
            color: '#F37254',
          },
        };
        console.log(options);
  
        const rzp = new window.Razorpay(options);
       await rzp.open();
      } catch (error) {
        console.error('Error during payment:', error);
        alert('Payment failed. Please try again.');
      }
    };
  
    
    
  





  render() {
    let catalog = Catalog.Book;
    switch (this.state.store) {
      case "books":
        catalog = Catalog.Book;
        break;
      case "lawyers":
        catalog = Catalog.Lawyer;
        break;
      case "firms":
        catalog = Catalog.Firm;
        break;

      default:
        return (
          <>
            {/* <Navigate replace to="/404" /> */}

            <Helmet>
              
          <title>{this.state.storeItem.Title}</title>

          <meta name="title" content={this.state.storeItem.Title} />
         
          <meta
            name="keywords"
            content={this.state.storeItem.meta_asp_additional_tags}
          />

        </Helmet>
        <button onClick={this.payNow}>Pay Now</button>
          </>
        );
    }
    if (this.state.loaded === true) {
      if (!this.state.storeItem.id) {
        return (
          <div className="store-wrap">
            <div className="top-action">
              <Link to={"/catalogs/" + this.state.store}> Back</Link>
            </div>
            <div className="store-item">
              <div className="store-item-info">
                <div className="store-item-title">
                  <span className="title">Item Not Found</span>
                </div>
              </div>
            </div>
          </div>
        );
      } 
      return (

        <>

        <Helmet>
              
        <title>{this.state.storeItem.Title}</title>

        <meta name="title" content={this.state.storeItem.Title} />
       
        <meta
          name="keywords"
          content={this.state.storeItem.meta_asp_additional_tags}
        />

      </Helmet>

        <div className="store-wrap">
          {this.state.error && (
            <>
              <Alert variant="danger" dismissible>
                <p>{this.state.error}</p>
              </Alert>
            </>
          )}
          {this.state.success && (
            <>
              <Alert variant="success" dismissible>
                <p>{this.state.success}</p>
              </Alert>
            </>
          )}
         
          <div className="store-item-detail">
            {this.state.store === "books" && (
              <div className="store-item-avathar">
                 <div className="store-item-main">

                  <TransitionGroup>

                    <CSSTransition

                      key={this.state.currentImageIndex}

                      timeout={500}

                      classNames="fade"

                    >

                      <img

                        src={this.state.currentimage}

                        alt={this.state.storeItem.Title}

                      />

                    </CSSTransition>

                  </TransitionGroup>

                  </div>
               
                {this.state.storeItem.gallery.length > 0 && (
                  <div className="store-item-gallery">
                    <div className="store-gallery-item">
                     
                    </div>
                    {this.state.storeItem.gallery.map((v, i) => {
                      return (
                        <div className="store-gallery-item" key={i}>
                          <img

                            // src={this.state.storeItem.image}

                            // currentimage={this.state.storeItem.image}

                            // onClick={this.handleChangeCurrentImage}

                            // alt={this.state.storeItem.Title}

                            />
                          
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}

            <div className="store-item-info">
              <div className="store-item-title">
                {this.state.store !== "books" && (
                  <div className="avathar">
                    <img
                      src={this.state.storeItem.image}
                      alt={this.state.storeItem.Title}
                    />
                  </div>
                )}
                <div className="title">{this.state.storeItem.Title}</div>
                <div className="subtitle">
                  {this.state.store === "books" && <>By </>}{" "}
                  {this.state.storeItem.SubTitle}
                </div>
              </div>
              <div className="store-item-detail">
                {this.state.store === "books" && (
                  <p>{this.state.storeItem.Category}</p>
                )}
                <table>
                  <tbody>
                    {Catalog.Session.isLogined &&
                      this.state.store !== "books" &&
                      this.state.storeItem.Identifier && (
                        <tr>
                          <th>{catalog.Profile.Identifier}</th>
                          <td> {this.state.storeItem.Identifier}</td>
                        </tr>
                      )}
                    {this.state.storeItem.DisplayDate && (
                      <tr>
                        <th>{catalog.Profile.DisplayDate}</th>
                        <td> {this.state.storeItem.DisplayDate}</td>
                      </tr>
                    )}
                     {this.state.storeItem.shipping_class && (
                      <tr>
                        <th>Shipping Class</th>
                        <td> {this.state.storeItem.shipping_class}</td>
                      </tr>
                    )}
                    {this.state.storeItem.ISBN && (
                      <tr>
                        <th>ISBN</th>
                        <td> {this.state.storeItem.ISBN}</td>
                      </tr>
                    )}
                     {this.state.storeItem.Edition && (
                      <tr>
                        <th>Edition</th>
                        <td> {this.state.storeItem.Edition}</td>
                      </tr>
                    )}
                    {this.state.storeItem.Edition && (
                      <tr>
                        <th>Author</th>
                        <td> {this.state.storeItem.Author}</td>
                      </tr>
                    )}
                    {this.state.storeItem.Publisher && (
                      <tr>
                        <th>Publisher</th>
                        <td> {this.state.storeItem.Publisher}</td>
                      </tr>
                    )}
                    {this.state.storeItem.Pages && (
                      <tr>
                        <th>Pages</th>
                        <td> {this.state.storeItem.Pages}</td>
                      </tr>
                    )}
                    {this.state.storeItem.Binding && (
                      <tr>
                        <th>Binding</th>
                        <td> {this.state.storeItem.Binding}</td>
                      </tr>
                    )}
                    {this.state.storeItem.PropertyList1 && catalog.Config.PropertyList1 &&  catalog.Config.PropertyList1.length > 0 && (
                        <tr>
                          <th>{catalog.Profile.PropertyList1}</th>
                          <td>
                        
                            {(Array.isArray(catalog.Config.PropertyList1) && catalog.Config.PropertyList1.length > 0) ? (
                              catalog.Config.PropertyList1.map((i) => (
                               
                                this.state.storeItem.PropertyList1 && this.state.storeItem.PropertyList1.includes(Number.parseInt(i.value)) && (
                                  <Badge bg="dark" key={i.value}>{i.label}</Badge>
                                )
                              ))
                            ) : (
                              <span>No properties available</span>
                            )}
                          </td>
                        </tr>
                      )}

                    {Catalog.Session.isLogined &&
                      this.state.store !== "books" && (
                        <>
                          {this.state.storeItem.contact.email_address && (
                            <tr>
                              <th>{catalog.Profile.email_address}</th>
                              <td>
                                {" "}
                                {this.state.storeItem.contact.email_address}
                              </td>
                            </tr>
                          )}
                          {this.state.storeItem.contact.phone_number && (
                            <tr>
                              <th>{catalog.Profile.phone_number}</th>
                              <td>
                                {" "}
                                {this.state.storeItem.contact.phone_number}
                              </td>
                            </tr>
                          )}
                          {this.state.storeItem.contact.website_link && (
                            <tr>
                              <th>{catalog.Profile.website_link}</th>
                              <td>
                                {" "}
                                <a
                                  href={
                                    this.state.storeItem.contact.website_link
                                  }
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {this.state.storeItem.contact.website_link}
                                </a>{" "}
                              </td>
                            </tr>
                          )}
                          {this.state.storeItem.contact.address && (
                            <tr>
                              <th>{catalog.Profile.address}</th>
                              <td> {this.state.storeItem.contact.address}</td>
                            </tr>
                          )}
                        </>
                      )}

                    {Catalog.Session.isLogined &&
                      this.state.storeItem.Price && (
                        <tr>
                          <th>{catalog.Profile.Price}</th>
                          <td>₹{this.state.storeItem.Price}</td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              <div className="store-action">
                {Catalog.Session.isLogined ? (
                  <>
                    {this.state.store === "books" && (
                      <>
                       
                        <Modal
                          show={this.state.modal}
                          onHide={this.handleClose}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              {this.state.storeItem.Title}
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <table>
                              <tbody>
                                {this.state.storeItem.Identifier && (
                                  <tr>
                                    <th>{catalog.Profile.Identifier}</th>
                                    <td> {this.state.storeItem.Identifier}</td>
                                  </tr>
                                )}
                                {this.state.storeItem.DisplayDate && (
                                  <tr>
                                    <th>{catalog.Profile.DisplayDate}</th>
                                    <td> {this.state.storeItem.DisplayDate}</td>
                                  </tr>
                                )}
                                {this.state.storeItem.shipping_class && (
                                  <tr>
                                    <th>{catalog.Profile.DisplayDate}</th>
                                    <td> {this.state.storeItem.shipping_class}</td>
                                  </tr>
                                )}
                                {this.state.storeItem.PropertyList1 && catalog.Config.PropertyList1 &&  catalog.Config.PropertyList1.length > 0 && (
                                  <tr>
                                    <th>{catalog.Profile.PropertyList1}</th>
                                    <td>
                                  
                                      {(Array.isArray(catalog.Config.PropertyList1) && catalog.Config.PropertyList1.length > 0) ? (
                                        catalog.Config.PropertyList1.map((i) => (
                                        
                                          this.state.storeItem.PropertyList1 && this.state.storeItem.PropertyList1.includes(Number.parseInt(i.value)) && (
                                            <Badge bg="dark" key={i.value}>{i.label}</Badge>
                                          )
                                        ))
                                      ) : (
                                        <span>No properties available</span>
                                      )}
                                    </td>
                                  </tr>
                                )}

                                {Catalog.Session.isLogined &&
                                  this.state.storeItem.Price && (
                                    <tr>
                                      <th>{catalog.Profile.Price}</th>
                                      <td> {this.state.storeItem.Price}</td>
                                    </tr>
                                  )}
                              </tbody>
                            </table>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={this.handleClose}
                            >
                              Close
                            </Button>
                            <Link
                              to={`/payment/${this.state.store}/${this.state.id}/${this.state.slug}`}
                              className="book-now-btn"
                              onClick={() => {
                                Catalog.RediarectLogin = `/catalogs/${this.state.store}/${this.state.id}/${this.state.slug}`;
                              }}
                            >
                              {this.state.store === "books" ? (
                                <> Confirm</>
                              ) : (
                                <>Contact</>
                              )}
                    </Link>
                          </Modal.Footer>
                        </Modal>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Link
                      to={"/login"}
                      className="book-now-btn"
                      onClick={() => {
                        Catalog.RediarectLogin = `/catalogs/${this.state.store}/${this.state.id}/${this.state.slug}`;
                      }}
                    >
                      {this.state.store === "books" ? (
                        <> Book Now</>
                      ) : (
                        <>Contact</>
                      )}
                    </Link>
                  </>
                )}
              </div>

              <button onClick={this.payNow}>Pay Now</button>
            </div>
            {this.state.store !== "books" &&
              this.state.storeItem.gallery.length > 0 && (
                <div className="store-item-avathar">
                  <div className="store-item-main">
                    <TransitionGroup>
                      <CSSTransition
                        key={this.state.currentImageIndex}
                        timeout={500}
                        classNames="fade"
                      >
                        <img
                          src={this.state.currentimage}
                          alt={this.state.storeItem.Title}
                        />
                      </CSSTransition>
                    </TransitionGroup>
                  </div>
                  {this.state.storeItem.gallery.length > 1 && (
                    <>
                      <div className="store-item-gallery">
                        {this.state.storeItem.gallery.map((v, i) => {
                          return (
                            <div className="store-gallery-item" key={i}>
                              <img
                                src={v.image}
                                currentimage={v.image}
                                onClick={this.handleChangeCurrentImage}
                                alt={this.state.storeItem.Title}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              )}
          {/* <div

            className="store-item-content"

            dangerouslySetInnerHTML={{

              __html: this.state.storeItem.Detail1 || "",

            }}

            ></div> */}
          </div>
        </div>

        </>

      );
    } else {
      return <>Loading</>;
    }
  }
}
export default PaymentController;
