import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import { AppContext } from "./../config/common";
import FormField from "../widgets/FormField";
import { SiteService } from "../services/site";
import FormFile from "../widgets/FormFile";
import FormRadioGroup from "../widgets/FormRadioGroup";
import Catalog from "./../Catalog";
import FormDatePicker from "../widgets/FormDatePicker";
import FormDropdown from "../widgets/FormDropdown";
import FormTextArea from "../widgets/FormTextArea";

class RegisterController extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    const frmId = "frmReg" + new Date().getTime();
    this.state = {
      ...props,
      formId: frmId,
      acceptLegalAgreement: false,
      formData: {
        first_name: null,
        last_name: null,
        username: null,
        email: null,
        mobile: null,
        password: null,
        cpassword: null,
        role: null,
      },
      formSecondData: {
        PrimaryImageId: null,
        Title: null,
        Identifier: null,
        DisplayDate: null,
        shipping_class: null,
        SubTitle: null,
        contact: {
          phone_number: null,
          email_address: null,
          address: null,
          website_link: null,
        },
        Property1: [],
        Property2: [],
        Property3: [],
        PropertyList1: [],
        PropertyList2: [],
        PropertyList3: [],
        Detail1: null,
        imagedata: null,
      },
      errorData: {
        first_name: null,
        last_name: null,
        username: null,
        email: null,
        mobile: null,
        password: null,
        cpassword: null,

        PrimaryImageId: null,
        Title: null,
        Identifier: null,
        DisplayDate: null,
        shipping_class: null,
        SubTitle: null,
        Property1: null,
        Property2: null,
        Property3: null,
        PropertyList1: null,
        PropertyList2: null,
        PropertyList3: null,
        Detail1: null,

        phone_number: null,
        email_address: null,
        address: null,
        website_link: null,
      },
      secondform: false,
      isSubmit: false,
      isSuccess: false,
      isloaded: false,
    };
  }
  componentDidMount() {
    this.intervalId = setInterval(this.refreshlist, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  refreshlist = async () => {
    if (!this.state.isloaded && Catalog.isLoad) {
      await Catalog.refreshConfig();
    }
    const isloaded = Catalog.isLoad;
    this.setState({ isloaded });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { settings } = this.context;
    let formData = this.state.formData;
    let formSecondData = this.state.formSecondData;
    let secondform = this.state.secondform;
    let errorData = {};
    let isInValid = false;

    let catelog = Catalog;
    for (const name in formData) {
      if (name === "cpassword") {
        const valid = catelog.validate(name, formData[name],formData['password']);
        if (valid && !valid.valid) {
          errorData[name] = valid.message;
          isInValid = true;
        }
      } else {
        const valid = catelog.validate(name, formData[name]);
        if (valid && !valid.valid) {
          errorData[name] = valid.message;
          isInValid = true;
        }
      }
    }
    let isSuccess = false;
    let isSubmit = isInValid ? false : true;
    this.setState({ errorData, isSubmit, isSuccess });
    if (!isInValid) {
      formData.username =
        (formData.first_name || "") +
        "-" +
        (formData.last_name || "") +
        "-" +
        new Date().getTime().toString();
      if (formData.role === "4") {
        const valid = Catalog.validate('accept_legal_agreement', this.state.acceptLegalAgreement);
        if (!valid || valid.valid) {
          const newForm = {
            ...formData,
            role: parseInt(formData.role),
            accept_legal_agreement: true,
          };
          const res = await SiteService.registerUser(newForm, settings);
          if (res.id) {
            Catalog.Message = "Registraion Success";
            window.scrollTo(0, 0);
            formData = {
              first_name: null,
              last_name: null,
              name: null,
              email: null,
              mobile: null,
              password: null,
              cpassword: null,
              role: null,
            };
            isSuccess = true;
          }
        } else {
          isInValid = true;
          errorData.accept_legal_agreement = valid.message;
        }
      } else {
        if (secondform) {
          const roles = {
            4: "Basic",
            5: "Lawyer",
            6: "Firm",
            7: "Judge",
          };
          switch (roles[formData.role]) {
            case "Lawyer":
              catelog = Catalog.Lawyer;
              break;
            case "Firm":
              catelog = Catalog.Firm;
              break;
            default:
              break;
          }
          for (const name in formSecondData) {
            if (name === "contact") {
              for (const cname in formSecondData.contact) {              
                  const valid = catelog.validate(cname, formSecondData.contact[cname]);
                  if (valid && valid.valid===false) {
                    errorData[cname] = valid.message;
                    isInValid = true;
                  }                
              }
            } else {
              const valid = catelog.validate(name, formSecondData[name]);
              if (valid && !valid.valid) {
                errorData[name] = valid.message;
                isInValid = true;
              }     
            }
            console.log(name,errorData)
          }
          isSuccess = false;
          isSubmit = isInValid ? false : true;
          this.setState({ errorData, isSubmit, isSuccess });
          if (!isInValid) {
            const valid = Catalog.validate('accept_legal_agreement', this.state.acceptLegalAgreement);
            if (!valid || valid.valid) {
              const newForm = {
                ...formData,
                role: parseInt(formData.role),
                accept_legal_agreement: true,
              };
              const res = await SiteService.registerUser(newForm, settings);
              if (res.id) {
                const newLawyer = {
                  ...formSecondData,
                  users_permissions_user:res.id,
                  SubCategory2:formData.role === "5" ? 0 : 1,
                  Property1:(formSecondData.Property1||[]).map((i)=>i.value),
                  Property2:(formSecondData.Property2||[]).map((i)=>i.value),
                  Property3:(formSecondData.Property3||[]).map((i)=>i.value),
                  PropertyList1:(formSecondData.PropertyList1||[]).map((i)=>i.value),
                  PropertyList2:(formSecondData.PropertyList2||[]).map((i)=>i.value),
                  PropertyList3:(formSecondData.PropertyList3||[]).map((i)=>i.value),
                  type_of_court:(formSecondData.type_of_court||[]).map((i)=>i.value),
                }
                console.log(newLawyer,(formSecondData.Property1||[]).map((i)=>i.value))
                formSecondData.users_permissions_user = res.id; 
                formSecondData.SubCategory2 = formData.role === "5" ? 0 : 1;
                await SiteService.registerLawyer(newLawyer);
                Catalog.Message = "Registraion Success";
                window.scrollTo(0, 0);
                formData = {
                  first_name: null,
                  last_name: null,
                  name: null,
                  email: null,
                  mobile: null,
                  password: null,
                  cpassword: null,
                  role: null,
                };
                isSuccess = true;
                secondform = false;
                formSecondData = {
                  PrimaryImageId: null,
                  Title: null,
                  Identifier: null,
                  DisplayDate: null,
                  shipping_class: null,
                  SubTitle: null,
                  contact: {
                    phone_number: null,
                    email_address: null,
                    address: null,
                    website_link: null,
                  },
                  Property1: [],
                  Property2: [],
                  Property3: [],
                  PropertyList1: [],
                  PropertyList2: [],
                  PropertyList3: [],
                  Detail1: null,
                  imagedata: null,
                };
              } else {
              }
            } else {
              isInValid = true;
              errorData.accept_legal_agreement = valid.message;
            }
          }
        } else {
          secondform = true;
        }
      }
      isSubmit = false;
      this.setState({
        formData,
        isSubmit,
        isSuccess,
        formSecondData,
        secondform,
      });
    }
    return !isInValid;
  };
  resetForm = async () => {
    const secondform = false;
    this.setState({ secondform });
  };
  handleFileChange = async (e) => {
    const { value, fileUrl, fileName, fileSize, fileMime } = e.target;
    const formSecondData = {
      ...this.state.formSecondData,
      PrimaryImageId: value,
      imagedata: {
        fileUrl: fileUrl,
        fileName: fileName,
        fileSize: fileSize,
        fileMime: fileMime,
      },
    };
    this.setState({ formSecondData });
  };
  handleSecondChange = async (e) => {
    const roles = {
      4: "Basic",
      5: "Lawyer",
      6: "Firm",
      7: "Judge",
    };
    let catelog = Catalog;
    switch (roles[this.state.formData.role]) {
      case "Lawyer":
        catelog = Catalog.Lawyer;
        break;
      case "Firm":
        catelog = Catalog.Firm;
        break;
      default:
        break;
    }
    const { name, value } = e.target;
    const formSecondData = {
      ...this.state.formSecondData,
      [name]: value,
    };
    this.setState({ formSecondData });

    const valid = catelog.validate(name, value);
    if (valid && !valid.valid) {
      const errorData = {
        ...this.state.errorData,
        [name]: valid.message,
      };
      this.setState({ errorData });
    } else {
      const errorData = {
        ...this.state.errorData,
        [name]: null,
      };
      this.setState({ errorData });
    }
  };

  handleContactChange = async (e) => {
    const roles = {
      4: "Basic",
      5: "Lawyer",
      6: "Firm",
      7: "Judge",
    };
    let catelog = Catalog;
    switch (roles[this.state.formData.role]) {
      case "Lawyer":
        catelog = Catalog.Lawyer;
        break;
      case "Firm":
        catelog = Catalog.Firm;
        break;
      default:
        break;
    }
    const { name, value } = e.target;
    let formSecondData = this.state.formSecondData;
    formSecondData.contact[name] = value;
    this.setState({ formSecondData });

    const valid = catelog.validate(name, value);
    if (valid && !valid.valid) {
      const errorData = {
        ...this.state.errorData,
        [name]: valid.message,
      };
      this.setState({ errorData });
    } else {
      const errorData = {
        ...this.state.errorData,
        [name]: null,
      };
      this.setState({ errorData });
    }
  };

  handleChange = async (e) => {
    let catelog = Catalog;
    const { name, value } = e.target;
    const formData = {
      ...this.state.formData,
      [name]: value,
    };
    this.setState({ formData });
    if(name==="cpassword"){
      const valid = catelog.validate(name, value,formData['password']);
      if (valid && !valid.valid) {
        const errorData = {
          ...this.state.errorData,
          [name]: valid.message,
        };
        this.setState({ errorData });
      } else {
        const errorData = {
          ...this.state.errorData,
          [name]: null,
        };
        this.setState({ errorData });
      }
    }else{
      const valid = catelog.validate(name, value);
      if (valid && !valid.valid) {
        const errorData = {
          ...this.state.errorData,
          [name]: valid.message,
        };
        this.setState({ errorData });
      } else {
        const errorData = {
          ...this.state.errorData,
          [name]: null,
        };
        this.setState({ errorData });
      }
    }
  };
  render() {
    const { settings } = this.context;
    return (
      <>
        <div className="signInWrapp">
          {this.state.isSuccess === true && (
            <>
              <Navigate replace to={"/login"} />
            </>
          )}

          {!this.state.isloaded || settings.show_auth ? (
            <>
              {Catalog.Session.isLogined ? (
                <>
                  <Navigate replace to="/account" />
                </>
              ) : (
                <div className="signInWrapp">
                  <div className="row row-one signInContainer">
                    <div
                      className={
                        this.state.secondform
                          ? "col-md-6 second-form"
                          : "col-md-4 first-form"
                      }
                    >
                      <div className="signIn-head">
                        <h3>Register</h3>
                        {/* <img src={logo} alt="logo" /> */}
                      </div>
                      {this.state.secondform ? (
                        <>
                          <FormTwoController
                            role={this.state.formData.role}
                            formId={this.state.formId}
                            resetForm={this.resetForm}
                            handleSubmit={this.handleSubmit}
                            handleContactChange={this.handleContactChange}
                            handleChange={this.handleSecondChange}
                            handleFileChange={this.handleFileChange}
                            isSubmit={this.state.isSubmit}
                            formData={this.state.formSecondData}
                            errorData={this.state.errorData}
                            acceptLegalAgreement={
                              this.state.acceptLegalAgreement
                            }
                            accept={(e) => {
                              const acceptLegalAgreement = e.target.checked;
                              this.setState({ acceptLegalAgreement });
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <FormOneController
                            formId={this.state.formId}
                            resetForm={this.resetForm}
                            handleSubmit={this.handleSubmit}
                            handleChange={this.handleChange}
                            isSubmit={this.state.isSubmit}
                            formData={this.state.formData}
                            errorData={this.state.errorData}
                            acceptLegalAgreement={
                              this.state.acceptLegalAgreement
                            }
                            accept={(e) => {
                              const acceptLegalAgreement = e.target.checked;
                              this.setState({ acceptLegalAgreement });
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <Navigate replace to="/404" />
            </>
          )}
        </div>
      </>
    );
  }
}
export default RegisterController;

class FormOneController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      catelog: Catalog,
      roles: [
        { value: "4", label: "Basic User" },
        { value: "5", label: "Register as an individual Lawyer" },
        { value: "6", label: "Register as Legal Firm" },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  render() {
    return (
      <Form
        id={this.state.formId}
        className="signInForm"
        onSubmit={this.state.handleSubmit}
        noValidate
      >
        <FormField
          id={this.state.formId + "firstName"}
          title={this.state.catelog.Strings.profile.first_name + "*"}
          value={this.state.formData?.first_name}
          error={this.state.errorData?.first_name}
          name={"first_name"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "lastName"}
          title={this.state.catelog.Strings.profile.last_name + "*"} 
          value={this.state.formData?.last_name}
          error={this.state.errorData?.last_name}
          name={"last_name"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "email"}
          type={"email"}
          title={this.state.catelog.Strings.profile.email + "*"}
          value={this.state.formData?.email}
          error={this.state.errorData?.email}
          name={"email"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "mobile"}
          title={this.state.catelog.Strings.profile.mobile + "*"}
          value={this.state.formData?.mobile}
          error={this.state.errorData?.mobile}
          name={"mobile"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "password"}
          type={"password"}
          title={this.state.catelog.Strings.profile.password + "*"}
          value={this.state.formData?.password}
          error={this.state.errorData?.password}
          name={"password"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "repassword"}
          type={"password"}
          title={this.state.catelog.Strings.profile.cpassword + "*"}
          value={this.state.formData?.cpassword}
          error={this.state.errorData?.cpassword}
          name={"cpassword"}
          handle={this.state.handleChange}
        />
        <FormRadioGroup
          id={this.state.formId + "role"}
          type={"radio"}
          value={this.state.formData?.role}
          error={this.state.errorData?.role}
          name={"role"}
          handle={this.state.handleChange}
          options={this.state.roles}
        />
        {(this.state.formData?.role ?? "") === "4" && (
          <div className="formfield">
            <div className="input-field">
              <Form.Group
                className="mb-3"
                controlId={this.state.formId + "accept_legal_agreement"}
              >
                <Form.Check
                  id={this.state.formId + "accept_legal_agreement"}
                  type={"checkbox"}
                  label={Catalog.Strings.profile.accept_legal_agreement}
                  name={"accept_legal_agreement"}
                  value={"accept legal agreement"}
                  checked={this.state.acceptLegalAgreement}
                  onChange={this.state.accept}
                  isInvalid={
                    this.state.errorData?.accept_legal_agreement ? true : false
                  }
                />

                <Form.Control.Feedback type="invalid">
                  {this.state.errorData?.accept_legal_agreement}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        )}
        <div className="formfield">
          <Button
            variant="primary"
            className={"signInBtn1"}
            type="submit"
            disabled={this.state.isSubmit}
          >
            {(this.state.formData?.role ?? "4") === "4" ? "Register" : "Next"}
          </Button>
        </div>
        <div className="signInFooter">
          <p>
            Already have an account? <Link to={"/login"}>Login here</Link>
          </p>
        </div>
      </Form>
    );
  }
}
class FormTwoController extends Component {
  constructor(props) {
    super(props);
    switch (
      {
        4: "Basic",
        5: "Lawyer",
        6: "Firm",
        7: "Judge",
      }[props.role]
    ) {
      case "Lawyer":
        this.state = {
          catelog: Catalog.Lawyer,
          ...props,
        };
        break;

      case "Firm":
        this.state = {
          catelog: Catalog.Firm,
          ...props,
        };
        break;
      default:
        this.state = {
          catelog: Catalog,
          ...props,
        };
        break;
    }
  }

  static getDerivedStateFromProps(props, state) {
    switch (
      {
        4: "Basic",
        5: "Lawyer",
        6: "Firm",
        7: "Judge",
      }[props.role]
    ) {
      case "Lawyer":
        return {
          catelog: Catalog.Lawyer,
          ...props,
        };

      case "Firm":
        return {
          catelog: Catalog.Firm,
          ...props,
        };
      default:
        return {
          catelog: Catalog,
          ...props,
        };
    }
  }
  render() {
    return (
      <Form
        id={this.state.formId}
        className="signInForm"
        onSubmit={this.state.handleSubmit}
        noValidate
      >
        <FormFile
          id={this.state.formId + "image"}
          type="file"
          title={this.state.catelog.Profile.PrimaryImageId}
          value={this.state.formData?.PrimaryImageId}
          error={this.state.errorData?.PrimaryImageId}
          name={"PrimaryImageId"}
          handle={this.state.handleFileChange}
          accept={".png,.jpeg,.jpg,.gif"}
          {...this.state.formData.imagedata}
        />
        <FormField
          id={this.state.formId + "title"}
          title={this.state.catelog.Profile.Title + "*"}
          value={this.state.formData?.Title}
          error={this.state.errorData?.Title}
          name={"Title"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "Identifier"}
          title={this.state.catelog.Profile.Identifier + "*"}
          value={this.state.formData?.Identifier}
          error={this.state.errorData?.Identifier}
          name={"Identifier"}
          handle={this.state.handleChange}
        />
         <FormField
          id={this.state.formId + "Shipping Class"}
          title={this.state.catelog.Profile.shipping_class + "*"}
          value={this.state.formData?.shipping_class}
          error={this.state.errorData?.shipping_class}
          name={"Shipping Class"}
          handle={this.state.handleChange}
        />
        <FormDatePicker
          id={this.state.formId + "displaydate"}
          title={this.state.catelog.Profile.DisplayDate}
          value={this.state.formData?.DisplayDate}
          error={this.state.errorData?.DisplayDate}
          name={"DisplayDate"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "subtitle"}
          title={this.state.catelog.Profile.SubTitle + "*"}
          value={this.state.formData?.SubTitle}
          error={this.state.errorData?.SubTitle}
          name={"SubTitle"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "professionalemail"}
          title={this.state.catelog.Profile.email_address + "*"}
          type={"email"}
          value={this.state.formData?.contact?.email_address}
          error={this.state.errorData?.email_address}
          name={"email_address"}
          handle={this.state.handleContactChange}
        />
        <FormField
          id={this.state.formId + "professionalphone"}
          title={this.state.catelog.Profile.phone_number + "*"}
          value={this.state.formData?.contact?.phone_number}
          error={this.state.errorData?.phone_number}
          name={"phone_number"}
          handle={this.state.handleContactChange}
        />
        <FormField
          id={this.state.formId + "address"}
          title={this.state.catelog.Profile.address + "*"}
          value={this.state.formData?.contact?.address}
          error={this.state.errorData?.address}
          name={"address"}
          handle={this.state.handleContactChange}
        />
        <FormField
          id={this.state.formId + "websitelink"}
          title={this.state.catelog.Profile.website_link + "*"}
          value={this.state.formData?.contact?.website_link}
          error={this.state.errorData?.website_link}
          name={"website_link"}
          handle={this.state.handleContactChange}
        />
        <FormDropdown
          id={this.state.formId + "state"}
          title={this.state.catelog.Profile.Property1}
          value={this.state.formData?.Property1}
          error={this.state.errorData?.Property1}
          options={this.state.catelog.Config.Property1}
          name={"Property1"}
          handle={this.state.handleChange}
          multiple={true}
        />
        <FormDropdown
          id={this.state.formId + "district"}
          title={this.state.catelog.Profile.Property3}
          value={this.state.formData?.Property3}
          error={this.state.errorData?.Property3}
          options={this.state.catelog.Config.Property3.filter((v) => {            
            let states = (this.state.formData?.Property1 ?? []);
            return (states.length === 0 || states.includes(v.state));
          })} 
          name={"Property3"}
          handle={this.state.handleChange}
          multiple={true}
        />

        <FormDropdown
          id={this.state.formId + "city"}
          title={this.state.catelog.Profile.Property2}
          value={this.state.formData?.Property2}
          error={this.state.errorData?.Property2}
          options={this.state.catelog.Config.Property2.filter((v) => {
            let states = (this.state.formData?.Property1 ?? []);
            let districts = (this.state.formData?.Property3 ?? []);
            return (states.length === 0 || states.includes(v.state))&&(districts.length === 0 || districts.includes(v.district));
          })} 
          name={"Property2"}
          handle={this.state.handleChange}
          multiple={true}
        />
        <FormDropdown
          id={this.state.formId + "specializationareas"}
          title={this.state.catelog.Profile.PropertyList1}
          value={this.state.formData?.PropertyList1}
          error={this.state.errorData?.PropertyList1}
          options={this.state.catelog.Config.PropertyList1}
          name={"PropertyList1"}
          handle={this.state.handleChange}
          multiple={true}
        />
        <FormDropdown
          id={this.state.formId + "courts"}
          title={this.state.catelog.Profile.PropertyList2}
          value={this.state.formData?.PropertyList2}
          error={this.state.errorData?.PropertyList2}
          options={this.state.catelog.Config.PropertyList2.filter((v) => {
            let states = (this.state.formData?.Property1 ?? []);
            let districts = (this.state.formData?.Property3 ?? []);
            let cities = (this.state.formData?.Property2 ?? []); 
 
            return (states.length === 0 ||!v.state|| states.includes(v.state))&&(districts.length === 0 || !v.district ||districts.includes(v.district))&&(cities.length === 0 ||!v.city|| cities.includes(v.city));
          })}
          name={"PropertyList2"}
          handle={this.state.handleChange}
          multiple={true}
        />
        <FormDropdown
          id={this.state.formId + "propertylist3"}
          title={this.state.catelog.Profile.PropertyList3}
          value={this.state.formData?.PropertyList3}
          error={this.state.errorData?.PropertyList3}
          options={this.state.catelog.Config.PropertyList3}
          name={"PropertyList3"}
          handle={this.state.handleChange}
          multiple={true}
        />
        <FormTextArea
          id={this.state.formId + "profiledetail"}
          title={this.state.catelog.Profile.Detail1 + "*"}
          value={this.state.formData?.Detail1}
          error={this.state.errorData?.Detail1}
          name={"Detail1"}
          handle={this.state.handleChange}
        />

        <div className="formfield">
          <div className="input-field">
            <Form.Group
              className="mb-3"
              controlId={this.state.formId + "accept_legal_agreement"}
            >
              <Form.Check
                id={this.state.formId + "accept_legal_agreement"}
                type={"checkbox"}
                label={Catalog.Strings.profile.accept_legal_agreement}
                name={"accept_legal_agreement"}
                value={"accept legal agreement"}
                checked={this.state.acceptLegalAgreement}
                onChange={this.state.accept}
                isInvalid={
                  this.state.errorData?.accept_legal_agreement ? true : false
                }
              />

              <Form.Control.Feedback type="invalid">
                {this.state.errorData?.accept_legal_agreement}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="formfield">
          <Button
            variant="outline-primary"
            className="signInBtn2"
            type="reset"
            onClick={this.state.resetForm}
            disabled={this.state.isSubmit}
          >
            Back
          </Button>
          <Button
            variant="primary"
            className="signInBtn1"
            type="submit"
            disabled={this.state.isSubmit}
          >
            Register
          </Button>
        </div>
        <div className="signInFooter">
          <p>
            Already have an account? <Link to={"/login"}>Login here</Link>
          </p>
        </div>
      </Form>
    );
  }
}
