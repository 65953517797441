import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Catalog from "../Catalog";
import { dateFormat } from "../config/common";
import DetailCard from "../widgets/DetailCard";
import { StoreService } from "../services/store";
import { BookService } from "../services/book";



import d1 from "./../images/d1.png";

import "./../css/widget/SearchCard.css";
class SimilarCartProducts extends Component {
  constructor(props) {
    super(props);
    console.log(Catalog.Book.Config,'HIII')
    this.state = {
      ...props,
      storeItems: {
        data: [],
        pagination: {
          page: 1,
          pageSize: 25,
          pageCount: 1,
          total: 0,
        },
      },
    
  
     
      news: props.news,
      active: props.active,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      news: props.news,
      active: props.active,
      ...props,
    };
  }



 
  handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("Form submitted:");
  };


  refreshlist=async ()=>{ 
    if(!this.state.isloaded&&Catalog.isLoad){
      await Catalog.refreshConfig();
    }
    const isloaded=Catalog.isLoad;
    this.setState({isloaded})
  }

  fetchAuthors=async ()=>{
    const authors=await BookService.fetchAuthors();
    this.setState({ authors }); 
  }

  fetchFilterIds=async ()=>{
    const storeName = this.state.store;
    const availableFilterData=await StoreService.fetchFilterIds(storeName);
    console.log(availableFilterData)
    this.setState({ availableFilterData });  
  }

  fetchStore=async (filterData={})=> {
    const storeName = this.state.store;
    this.setState({ storeName });  
    const storeItems = await StoreService.fetchStore(storeName,filterData); 
    this.setState({ storeItems });
  }
  handleChange=(e)=>{
    const {name,value,checked} = e.target;
    let filterData = this.state.filterData;
 
    if(checked){
      filterData[name]=[...filterData[name],value];
    }else{
      filterData[name]=filterData[name].filter((v,k)=>v!==value)
    }
    const storeItems ={
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    };
    console.log(filterData);
    this.setState({ filterData ,storeItems })
    this.fetchStore(filterData);
  }

  handleChangeDropDown=(e)=>{
    const {name,value} = e.target;
    const filterData = {
      ...this.state.filterData,
      [name]:value
    };
    const storeItems ={
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    }; 
    this.setState({ filterData ,storeItems })
    this.fetchStore(filterData);
  }
  // handlePriceFilterChange=(e)=>{
  //   const {name,value,checked} = e.target;
  //   let filterData = this.state.filterData;
  //   filterData.Price = []; 

  //   if(checked){
  //     filterData[name]=[...filterData[name],value];
  //   }else{
  //     filterData[name]=filterData[name].filter((v,k)=>v!==value)
  //   }

  //   const storeItems ={
  //     data: [],
  //     pagination: {
  //       page: 1,
  //       pageSize: 25,
  //       pageCount: 1,
  //       total: 0,
  //     },
  //   };
  //   console.log(filterData);
  //   this.setState({ filterData ,storeItems })
  //   this.fetchStore(filterData);

  // }

  handlePriceFilterChange = (selectedPriceRanges) => {
    const { filterData } = this.state;

    filterData.Price = selectedPriceRanges; 
    
    const storeItems = {
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    };

    this.setState({ filterData, storeItems }, () => {
   
      this.fetchStore(filterData); 
    });
  };
  
  handleBindingFilterChange = (newSelectedValues) => {
    const { filterData } = this.state;

    filterData.Binding = newSelectedValues; 
    
    const storeItems = {
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    };

    this.setState({ filterData, storeItems }, () => {
   
      this.fetchStore(filterData); 
    });
  };

  handleTypeFilterChange = (newSelectedValues) => {
    const { filterData } = this.state;

    filterData.Type = newSelectedValues; 
    
    const storeItems = {
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    };

    this.setState({ filterData, storeItems }, () => {
   
      this.fetchStore(filterData); 
    });
  };

  getAvailablePriceRanges(availableFilterData) {
   
    const prices = availableFilterData.Price.map(item => item); 
  
   
    if (prices.length === 0) return [];
  
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
  
    const ranges = [];
  
    const filteredRanges = [];
  
    if (maxPrice > 0) {
      let currentMax = 0; 
      while (currentMax <= maxPrice) {
        const nextMax = currentMax + 500;
        const label = `${currentMax + 1} - ${nextMax}`;
        const value = `${currentMax + 1}-${nextMax}`;
        
       
        const rangePrices = prices.filter(price => price >= currentMax + 1 && price <= nextMax);
        if (rangePrices.length > 0) {
          filteredRanges.push({ label, value });
        }
        
        currentMax = nextMax; 
      }
    }
  
    return filteredRanges;
  }

  

  getMaxPrice(availableFilterData) {
 
    const prices = availableFilterData.Price.map(item => item);

    const maxPrice = Math.max(...prices);

    const roundToNearest = (value) => {
        const magnitude = Math.pow(10, Math.floor(Math.log10(value)));  

        return Math.ceil(value / magnitude) * magnitude;  
    };
      return roundToNearest(maxPrice);
  }

  
  handleSearchResult = (searchQuery) => {

    console.log("Search query received:", searchQuery);
    const { filterData } = this.state;

    filterData.Search = searchQuery; 
    
    const storeItems = {
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    };

    this.setState({ filterData, storeItems }, () => {
   
      this.fetchStore(filterData); 
    });
    
  };
  
  
  
  componentDidMount() {

   

    window.scrollTo(0, 0); 
    this.intervalId=setInterval(this.refreshlist, 1000); 
    if(this.state.store==="books"){
      this.fetchAuthors();
    }
    this.fetchStore(this.state.filterData);
    this.fetchFilterIds();
  }
  componentDidUpdate() {
    if (this.state.store !== this.state.storeName) {
      this.fetchStore();
    }
    // this.state.storeItems.data.map((storeItem, index) => {
    //   console.log("Updated storeItems:", storeItem.Price);
    // })
  }

  componentWillUnmount() { 
     
    clearInterval(this.intervalId);
  }
 
 
  
  render() {

    

    // console.log(this.state.storeItems,'plural');
    


    const filterdata = this.state.storeItems.data?.filter((item) => {
        const itemId = item.Category?.data?.[0]?.id; // Item's category ID
        const categoryId = this.state?.data[0]?.Category?.data?.id; // First category ID in state data
        const itembookid = item.id; // Item's unique ID
        const categorybookid = this.state?.data?.id; // State data's unique ID (seems unused)
    
        // console.log(categoryId, 'fresh'); 
        // console.log(itemId, categoryId, 'Comparing values');
    
        // Create an array of objects containing categoryId and itemId
        const cartitemsId = this.state?.data
            ?.filter((items) => items?.Category?.data?.[0]?.id && items?.id)
            .map((items) => ({
                categoryId: items.Category?.data[0].id, 
                itemId: items.id,
            }));
    
        // console.log(cartitemsId, 'old');
    
        // Check if itemId and categoryId are not in the cartitemsId array
        const isCategoryIdMatch = cartitemsId.some(cartItem => String(cartItem.categoryId) === String(itemId));
        const isItemIdMismatch = cartitemsId.every(cartItem => String(cartItem.itemId) !== String(itembookid));
    
        // Return true if the conditions are met
        return isCategoryIdMatch && isItemIdMismatch;
    });
    
    

    
    
    const limitedData = filterdata?.slice(0, 4);

        

    
    return (
      <div>
        <div className="data">
          {limitedData?.length > 0 ? (
            limitedData.map((item, i) => {
              // console.log(item.Category?.data?.[0]?.id, 'computer');
    
              return (
                <DetailCard
                  key={i}
                  id={item.id}
                  name={item.Title}
                  img={item.PrimaryImageId}
                  Op={item.Price}
                  np={(item.Price * (100 - item.discount)) / 100}
                  discount={item.discount}
                  Author={item.author_names}
                  description={item.ShortDescription}
                  similaritem={item}
                />
              );
            })
          ) : (
            null
          )}
        </div>
      </div>
      
    );
  }
}
export default SimilarCartProducts;
