import React from 'react'
import "./../css/Lawer.css";
import FindLawerController from '../controllers/FindLawerController'
import AllLawerData from '../controllers/AllLawerData';

const FindALawerPlace = () => {
  return (
    <div>
        <div>
          <AllLawerData />
        

        </div>


                <FindLawerController></FindLawerController>
                
    </div>
  )
}

export default FindALawerPlace
